import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { Device } from '@ionic-native/device/ngx';

import { ModalController, ToastController } from '@ionic/angular';
import { CartService } from 'src/app/common/services/cart/cart.service';
import { AuthService } from '../../../core/services/auth/auth.service';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { BarcodeScanner } from '@capacitor-community/barcode-scanner';
import { QrScannerService, QrSNotification } from '../../services/qr-scanner/qr-scanner.service';
import { ModalService } from '../../services/modal/modal.service';

@Component({
  selector: 'app-qr-scanner',
  templateUrl: './qr-scanner.component.html',
  styleUrls: ['./qr-scanner.component.scss'],
})
export class QrScannerComponent implements OnInit, OnDestroy  {

  public static readonly id = 'qr-scanner-modal';
  mobileView = false;
  scannerEnabled = false;

  public restaurantId: string | null = null;
  public restaurantBranchId: string | null = null;
  public tableId: string | null = null;

  constructor(private zone: NgZone,
    private device: Device,
    private authService: AuthService,
    private modalController: ModalController, 
    private cartService: CartService, 
    private toastController: ToastController, private router: Router,
    private qrScannerService: QrScannerService,
    public modalService: ModalService,
  ) { 
  }
  
  public onCloseClick() {
    this.modalController.dismiss({ value: '' }, 'close', QrScannerComponent.id);
  }

  public ngOnInit(): void {
    /**
     * @description Allowing only for devices not for browsers.
     * For Information, for browsers the value is null.
     */
    if (this.device.platform) {

      this.startScanning();
      this.scannerEnabled = false;
      this.mobileView = true;
    } else {

      this.scannerEnabled = true;
      this.mobileView = false;
    }
  }

  public ngOnDestroy(): void {
    
    this.stopScanning();
  }

  private async stopScanning() {
    (window.document.querySelector('ion-app') as HTMLElement).classList.remove('camera-view');
    if (this.mobileView) {
      BarcodeScanner.showBackground();
      BarcodeScanner.stopScan();
    }  
  }

  private async startScanning() {

    (window.document.querySelector('ion-app') as HTMLElement).classList.add('camera-view');
    
    
      // check or request permission
      const status = await BarcodeScanner.checkPermission({ force: true });
      if (status.granted) {
        // the user granted permission
        BarcodeScanner.hideBackground(); // make background of WebView transparent
    
        const result = await BarcodeScanner.startScan(); // start scanning and wait for a result
      
        // if the result has content
        if (result.hasContent) {
          console.log(result.content); // log the raw scanned content
          this.scanSuccessHandler(result.content);
        }
      } else {
        return false;
      }
  }

  public scanSuccessHandler(qrCode: any) {
    this.scannerEnabled = false;

    const params = this.extractQueryParamsFromUrl(qrCode);
  
    if (params) {
      this.restaurantId = params.restaurantId;
      this.restaurantBranchId = params.restaurantBranchId;
      this.tableId = params.tableId; 
      this.forwardToMenuPage();
    } else {
      console.error('Invalid QR Code format.');
    }
  }
  
  private extractQueryParamsFromUrl(url: string): any {
    try {
      const queryParams = url.split('?')[1];
      if (queryParams) {
        const params = queryParams.split('&');
        const result: any = {};

        for (const param of params) {
          const [key, value] = param.split('=');
          result[key] = value;
        }

        return result;
      }
  
      return null;
    } catch (error) {
      console.error('Error extracting query parameters:', error);
      return null;
    }
  }

  private forwardToMenuPage() {
    const extras: NavigationExtras = {
      queryParams: {
        restaurantId: this.restaurantId,
        restaurantBranchId: this.restaurantBranchId,
        tableId: this.tableId,
      }
    };
    this.zone.run(() => {
      this.router.navigate(['/customer/catalog'], extras).then(() => {
        this.cartService.init(); // calling after navigation is completed..
        this.modalController.dismiss({ value: extras.queryParams }, 'close-complete', QrScannerComponent.id);
      });
    });

  }

  private async showToast(message: string) {
    const toast = await this.toastController.create({
      message,
      duration: 2000
    });
    toast.present();
  }

}
