import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import {
  MenuRestControllerService,
  RestaurantEntity,
  RestaurantRestControllerService,
  SmartDineResponseMenuEntity,
  MenuEntity,
  SmartDineResponseRestaurantEntity,
} from 'src/app/swagger';
import { MenuNavService } from '../../services/menu-nav/menu-nav.service';

@Component({
  selector: 'app-sd-menu-nav-bar',
  templateUrl: './menu-nav-bar.component.html',
  styleUrls: ['./menu-nav-bar.component.scss'],
})
export class MenuNavBarComponent implements OnInit {

  public restaurantId: string | null = null;
  public restaurantBranchId: string | null = null;
  public tableId: string | null = null;
  public restaurant: RestaurantEntity | null = null;

  public menus: MenuEntity[] = [];

  public forCustomer = false;

  constructor(
    activatedRoute: ActivatedRoute,
    private menuNavService: MenuNavService,
    private router: Router,
    private menuRestControllerService: MenuRestControllerService,
    private restaurantRestControllerService: RestaurantRestControllerService,
  ) {
    const currentUrl = this.router.routerState.snapshot.url;
    this.forCustomer = currentUrl.startsWith('/customer');

    activatedRoute.queryParams.subscribe((queryParams) => {
      this.restaurantId = queryParams?.restaurantId || null;
      this.restaurantBranchId = queryParams?.restaurantBranchId || null;
      this.tableId = queryParams?.tableId || null;
      this.init();
    });
  }

  public ngOnInit() {
    this.init();
  }

  public onHomeMenuClick(_: MouseEvent) {
    this.menuNavService.clear();
    const extras: NavigationExtras = { queryParams: {} };
    if (this.restaurantId) {
      extras.queryParams.restaurantId = this.restaurantId;
    }
    if (this.restaurantBranchId) {
      extras.queryParams.restaurantBranchId = this.restaurantBranchId;
    }
    if (this.tableId) {
      extras.queryParams.tableId = this.tableId;
    }
    const currentUrl = this.router.routerState.snapshot.url;
    const baseUrl = currentUrl.match(/(.*)menus/g)[0];
    this.router.navigateByUrl(baseUrl);
    this.router.navigate([baseUrl], extras);
  }

  public onMenuClick(_: MouseEvent, menu: MenuEntity, index: number) {
    this.menus.splice(index + 1);
    this.menuNavService.set(this.menus);
    const extras: NavigationExtras = { queryParams: {} };
    if (menu.restaurantId) {
      extras.queryParams.restaurantId = menu.restaurantId;
    }
    if (menu.restaurantBranchId) {
      extras.queryParams.restaurantBranchId = menu.restaurantBranchId;
    }
    if (this.tableId) {
      extras.queryParams.tableId = this.tableId;
    }
    const currentUrl = this.router.routerState.snapshot.url;
    const baseUrl = currentUrl.match(/(.*)menus/g)[0];
    this.router.navigate([baseUrl, menu.menuId], extras);
  }

  private init() {
    this.setMenus();

    if (!this.forCustomer && this.restaurantId) {
      this.setRestaurantInfo();
    }
  }

  private setMenus() {
    this.menuNavService.getMenus().subscribe({
      next: (menus: MenuEntity[]) => {
        this.menus = menus;
      }
    });
  }

  private setRestaurantInfo() {
    this.restaurantRestControllerService.getRestaurant(this.restaurantId).subscribe({
      next: (restaurantInfo: SmartDineResponseRestaurantEntity) => {
        this.restaurant = restaurantInfo.item;
      },
      error: (err) => {
        console.error('Unable to get restaurant info!', err);
      }
    });
  }

  public rebuild(menuId?: string) {
    this.menus = [];
  }

  private setMenu(menuId: string) {
    this.menuRestControllerService.getMenu(menuId).subscribe(
      (res: SmartDineResponseMenuEntity) => {
        // this.menu.res.item;
        // this.form.patchValue(this.menu);
      },
      (err) => {
        console.error('Unable to get menu details!', err);
      }
    );
  }

}
