import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Auth } from '@aws-amplify/auth';
import { Configuration } from 'src/app/swagger';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private session$: BehaviorSubject<CognitoUserSession | null> = new BehaviorSubject(null);

  private lock = false;

  public static configuration: Configuration = new Configuration({
    accessToken: ''
  });
  
  public static getConfiguration() {
    return AuthService.configuration;
  }

  public async init() {
    await this.getSession(true);
  }

  public async refresh() {
    await this.getSession(true);
  }

  public setSession(session: CognitoUserSession | null) {
    const accessToken = session?.getAccessToken().getJwtToken() || '';
    AuthService.configuration.accessToken = accessToken;

    this.session$.next(session);
  }

  public getSession(forceRefresh = false) {
    if (forceRefresh || !this.lock) {
      this.lock = true;
      this.loadSession();
    }
    return this.session$.asObservable();
  }

  public clearSession() {
    AuthService.configuration.accessToken = '';
    Auth.signOut().then(() => {
      this.session$.next(null);
    });
  }

  private loadSession() {
    return Auth.currentSession().then(
      (currentSession) => {
        const t = new Date();
        console.log(t, 'Updating session..');
        this.setSession(currentSession);
      },
      (err) => {
        console.error('Unable to get current session!', err);
      },
    );
  }
}
