import { MenuItem } from "src/app/common/services/side-menu/side-menu.service";
import { ProfileModel } from "src/app/swagger";


export const sideMenuItems: MenuItem[] = [
  {
    id: 'HOME',
    iconName: 'house-fill',
    iconSource: 'bootstrap',
    name: 'Home',
    link: '/resto-admin/home',
    pages: ['HomePage'],
    enabled: false,
    functionality_name: ProfileModel.AllowedFunctionalitiesByRestaurantIdEnum.HOME,
  },
  {
    id: 'RESTAURANTS',
    iconName: 'restaurant-outline',
    name: 'Restaurants',
    link: '/resto-admin/restaurants',
    pages: ['RestaurantsPage'],
    enabled: false,
    functionality_name: ProfileModel.AllowedFunctionalitiesByRestaurantIdEnum.RESTAURANTS,
  },
  {
    id: 'MENUS',
    iconName: 'grid-outline',
    name: 'Menus',
    link: '/resto-admin/menus',
    pages: ['MenusPage', 'MenuDetailsPage'],
    enabled: false,
    functionality_name: ProfileModel.AllowedFunctionalitiesByRestaurantIdEnum.MENU,
  },
  {
    id: 'FOOD_ITEMS',
    iconName: 'fast-food-outline',
    name: 'Food Items',
    link: '/resto-admin/items',
    pages: ['ItemsPage', 'ItemDetailsPage'],
    enabled: false,
    functionality_name: ProfileModel.AllowedFunctionalitiesByRestaurantIdEnum.ITEMS,
  },
  {
    id: 'ORDER_MANAGEMENT',
    iconName: 'albums-outline',
    name: 'Order Management',
    link: '/resto-admin/orders',
    pages: ['OrdersPage', 'PaymentPage'],
    enabled: false,
    functionality_name: ProfileModel.AllowedFunctionalitiesByRestaurantIdEnum.ORDERS,
    label: 'Payment & Reviews'
  },
  {
    id: 'CAPTAIN_ORDER',
    iconName: 'newspaper-outline',
    name: 'Captain Order',
    link: '/resto-admin/captain-orders',
    pages: ['RaOrdersPage'],
    enabled: false,
    functionality_name: ProfileModel.AllowedFunctionalitiesByRestaurantIdEnum.CAPTAINORDERS,
  },
  {
    id: 'KITCHEN_MANAGEMENT',
    iconName: 'bonfire-outline',
    name: 'Kitchen Management',
    link: '/resto-admin/kitchen',
    pages: ['KitchenPage'],
    enabled: false,
    functionality_name: ProfileModel.AllowedFunctionalitiesByRestaurantIdEnum.KITCHEN,
    // badge: {
    //   count: 5,
    // }, // TODO
  },
  {
    id: 'QR_CODE',
    iconName: 'qr-code-outline',
    name: 'QR Code',
    link: '/resto-admin/qr-codes',
    pages: ['QrCodesPage'],
    enabled: false,
    functionality_name: ProfileModel.AllowedFunctionalitiesByRestaurantIdEnum.QRCODE,
  },   
  {
    id: 'TAX_MANAGEMENT',
    iconName: 'briefcase-outline',
    name: 'Tax Management',
    link: '/resto-admin/tax',
    pages: ['TaxmanagementPage'],
    enabled: false,
    functionality_name: ProfileModel.AllowedFunctionalitiesByRestaurantIdEnum.TAX
  },
  {
    id: 'OFFER_MANAGEMENT',
    iconName: 'gift-outline',
    name: 'Offer Management',
    link: '/resto-admin/offers',
    pages: ['OffermanagementPage'],
    enabled: false,
    functionality_name: ProfileModel.AllowedFunctionalitiesByRestaurantIdEnum.OFFER,
  },
  {
    id: 'DAILY_EXPENSES',
    iconName: 'calculator',
    iconSource: 'bootstrap',
    name: 'Daily Expenses',
    link: '/resto-admin/daily-expenses',
    pages: ['DailyExpensesPage'],
    enabled: false,
    functionality_name: ProfileModel.AllowedFunctionalitiesByRestaurantIdEnum.DAILY_EXPENSES
  },
  {
    id: 'REPORTS',
    iconName: 'table',
    iconSource: 'bootstrap',
    name: 'Reports',
    link: '/resto-admin/reports',
    pages: ['ReportsPage'],
    enabled: true,
    functionality_name: ProfileModel.AllowedFunctionalitiesByRestaurantIdEnum.REPORTS,
  },
  {
    id: 'INVENTORY',
    iconName: 'boxes',
    iconSource: 'bootstrap',
    name: 'Inventory',
    link: '/resto-admin/inventory',
    pages: ['InventoryPage'],
    enabled: false,
    functionality_name: ProfileModel.AllowedFunctionalitiesByRestaurantIdEnum.INVENTORY
  },
  {
    id: 'TABLE_BOOKING',
    iconName: 'calendar-outline',
    name: 'Table Booking',
    link: '/resto-admin/table-booking',
    pages: ['TableBookingPage'],
    enabled: false,
    functionality_name: ProfileModel.AllowedFunctionalitiesByRestaurantIdEnum.TABLE_BOOKING
  },
];
