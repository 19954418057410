import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Auth } from 'aws-amplify';
import { RestaurantsService } from 'src/app/common/services/restaurants/restaurants.service';
import { RestaurantRestControllerService, RestaurantModel} from 'src/app/swagger';

@Component({
  selector: 'app-restaurant-modal',
  templateUrl: './restaurant-modal.component.html',
  styleUrls: ['./restaurant-modal.component.scss'],
})
export class RestaurantModalComponent implements OnInit {
  public restaurants: RestaurantModel[] = [];
  public currentPage: number = 1;
  public itemsPerPage: number = 10;
  public totalRecords: number = 0;
  public selectedRestaurant: RestaurantModel = null;

  constructor(
    private modalController: ModalController,
    private restaurantRestControllerService: RestaurantRestControllerService,
    private restaurantService: RestaurantsService
  ) {}

  ngOnInit() {
    this.selectedRestaurant = this.restaurantService.getSelectedRestaurant();
    this.loadRestaurants(this.currentPage, this.itemsPerPage);
  }

  get totalPages() {
    return Math.ceil(this.restaurants.length / this.itemsPerPage);
  }

  private loadRestaurants(pageNo: number, rows: number) {
    Auth.currentSession().then((currentSession) => {
      this.restaurantRestControllerService.configuration.accessToken = currentSession.getAccessToken().getJwtToken();
      this.restaurantRestControllerService.getRestaurants1(undefined, pageNo, rows).subscribe({
          next: (res) => {
            this.totalRecords = res.items.length || this.totalRecords;
            const newRestaurants = res.items.filter((item) => !this.restaurants.find((r) => r.restaurantId === item.restaurantId));
            this.restaurants.push(...newRestaurants);
          },
          error: (err) => {
            console.error('Unable to get restaurant details!', err);
          },
        });
    });
  }

  public loadData(event: any) {
    if (this.currentPage > this.totalPages) {
      event.target.disabled = true;
      return;
    }

    setTimeout(() => {
      this.currentPage++;
      this.loadRestaurants(this.currentPage, this.itemsPerPage);
      event.target.complete();
    }, 500);
  }

  public dismiss() {
    this.modalController.dismiss();
  }

  public selectRestaurant(restaurant: RestaurantModel) {
    this.restaurantService.setSelectedRestaurant(restaurant);
    this.selectedRestaurant = restaurant;
    this.modalController.dismiss(restaurant);
  }
}
