import { Component, OnInit } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { MessageService } from '../../services/message/message.service';
@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss'],
})
export class MessagesComponent implements OnInit {

  public message: string = null;  
  private messageSubscription: Subscription;
  constructor(private messageService: MessageService, private alertController: AlertController) { 
    
  }

  ngOnInit() {
    this.messageSubscription = this.messageService.getCurrentMessages().subscribe(async (message) => {
    this.message = message;
    if (message != null && message.length > 0) {
        //console.log('message ', message);
        const alert = await this.alertController.create({
          header: 'Alert',
          message: message,
          buttons: ['OK'],
        });
    
        await alert.present();
    }      
    });
  }
  ngOnDestroy() {
    this.messageSubscription.unsubscribe();
  }
}
