/**
 * SmartdineNow API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ReportInfoModel {
  fromDate?: Date;
  toDate?: Date;
  reportType?: ReportInfoModel.ReportTypeEnum;
  reportName?: string;
}
export namespace ReportInfoModel {
  export type ReportTypeEnum =
    | 'MADHUSHALA_LIQUOR_BILL_DETAILS'
    | 'MADHUSHALA_LIQUOR_BILL_TAX_DETAILS'
    | 'SALES_REPORT_BY_ORDERS'
    | 'SALES_REPORT_BY_ITEMS'
    | 'STOCKS_REPORT_BY_ITEMS'
    | 'ITEM_PROFITABILITY'
    | 'GST_REPORT'
    | 'SUMMARIZED_SALES_BY_ORDER'
    | 'SUMMARIZED_SALES_BY_ORDER_TYPE'
    | 'DAILY_EXPENSES'
    | 'DAILY_EXPENSES_SUMMARY';
  export const ReportTypeEnum = {
    MADHUSHALALIQUORBILLDETAILS:
      'MADHUSHALA_LIQUOR_BILL_DETAILS' as ReportTypeEnum,
    MADHUSHALALIQUORBILLTAXDETAILS:
      'MADHUSHALA_LIQUOR_BILL_TAX_DETAILS' as ReportTypeEnum,
    SALESREPORTBYORDERS: 'SALES_REPORT_BY_ORDERS' as ReportTypeEnum,
    SALESREPORTBYITEMS: 'SALES_REPORT_BY_ITEMS' as ReportTypeEnum,
    STOCKSREPORTBYITEMS: 'STOCKS_REPORT_BY_ITEMS' as ReportTypeEnum,
    ITEMPROFITABILITY: 'ITEM_PROFITABILITY' as ReportTypeEnum,
    GSTREPORT: 'GST_REPORT' as ReportTypeEnum,
    SUMMARIZEDSALESBYORDER: 'SUMMARIZED_SALES_BY_ORDER' as ReportTypeEnum,
    SUMMARIZEDSALESBYORDERTYPE:
      'SUMMARIZED_SALES_BY_ORDER_TYPE' as ReportTypeEnum,
    DAILYEXPENSES: 'DAILY_EXPENSES' as ReportTypeEnum,
    DAILY_EXPENSESSUMMARY: 'DAILY_EXPENSES_SUMMARY' as ReportTypeEnum,
  };
}
