/**
 * SmartdineNow API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { RazorPayPaymentGatewayRequest } from './razorPayPaymentGatewayRequest';
import { RazorPayPaymentGatewayResponse } from './razorPayPaymentGatewayResponse';

export interface PaymentTransactionModel { 
    paymentTransactionId?: string;
    restaurantId?: string;
    restaurantBranchId?: string;
    userId?: string;
    orderId?: string;
    paymentStatus?: PaymentTransactionModel.PaymentStatusEnum;
    razorPayPaymentGatewayRequest?: RazorPayPaymentGatewayRequest;
    razorPayPaymentGatewayResponse?: RazorPayPaymentGatewayResponse;
}
export namespace PaymentTransactionModel {
    export type PaymentStatusEnum = 'INITIATED' | 'CREATED' | 'AUTHORIZED' | 'CAPTURED' | 'REFUNDED' | 'FAILED' | 'PAY_BY_CASH' | 'PAY_LATER';
    export const PaymentStatusEnum = {
        INITIATED: 'INITIATED' as PaymentStatusEnum,
        CREATED: 'CREATED' as PaymentStatusEnum,
        AUTHORIZED: 'AUTHORIZED' as PaymentStatusEnum,
        CAPTURED: 'CAPTURED' as PaymentStatusEnum,
        REFUNDED: 'REFUNDED' as PaymentStatusEnum,
        FAILED: 'FAILED' as PaymentStatusEnum,
        PAYBYCASH: 'PAY_BY_CASH' as PaymentStatusEnum,
        PAYLATER: 'PAY_LATER' as PaymentStatusEnum
    };
}