import { MenuItem } from "src/app/common/services/side-menu/side-menu.service";

export const sideMenuItems: MenuItem[] = [
  {
    id: 'HOME',
    iconName: 'home-outline',
    name: 'Home',
    link: '/admin/home',
    pages: ['HomePage'],
    enabled: true,
    functionality_name: null
  },
  {
    id: 'RESTAURANTS',
    iconName: 'restaurant-outline',
    name: 'Restaurants',
    link: '/admin/restaurants',
    pages: ['RestaurantsPage', 'RestaurantDetailsPage', 'RestaurantBranchesPage'],
    enabled: true,
    functionality_name: null
  },
  {
    id: 'BRANCHES',
    iconName: 'menu-outline',
    name: 'Branches',
    link: '/admin/branches/',
    pages: ['RestaurantBranchesPage', 'RestaurantDetailsPage'],
    enabled: true,
    functionality_name: null
  },
  {
    id: 'MENU_TEMPLATES',
    iconName: 'grid-outline',
    name: 'Menu Templates',
    link: '/admin/menus',
    pages: ['MenusPage', 'MenuDetailsPage'],
    enabled: true,
    functionality_name: null
  },
  {
    id: 'FOOD_ITEM_TEMPLATES',
    iconName: 'fast-food-outline',
    name: 'Food Item Templates',
    link: '/admin/items',
    pages: ['ItemsPage', 'ItemDetailsPage'],
    enabled: true,
    functionality_name: null
  },
  {
    id: 'GROUP_MANAGEMENT',
    iconName: 'albums-outline',
    name: 'Group Management',
    link: '/admin/group-mgmt',
    pages: ['GroupMgmtPage'],
    enabled: true,
    functionality_name: null
  },
  {
    id: 'USER_MANAGEMENT',
    iconName: 'people-outline',
    name: 'User Management',
    link: '/admin/user-mgmt',
    pages: ['UserMgmtPage'],
    enabled: true,
    functionality_name: null
  },
];