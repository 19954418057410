import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController, ToastController , AlertController  } from '@ionic/angular';
import { UserProfileService } from 'src/app/common/services/user-profile/user-profile.service';
import { MenuEntity, MenuRestControllerService, ProfileModel, SmartdineImageRestControllerService, SmartDineResponseMenuEntity } from 'src/app/swagger';
import { LoaderService } from '../../services/loader/loader.service';
import { MenuSelectionComponent } from '../menu-selection/menu-selection.component';
@Component({
  selector: 'app-menu-upsertion',
  templateUrl: './menu-upsertion.component.html',
  styleUrls: ['./menu-upsertion.component.scss'],
})
export class MenuUpsertionComponent implements OnInit {

  public static readonly id = 'menu-upsertion-modal';
  public selImg: string;

  @Input()
  public menuId: string | null = null;

  @Input()
  public restaurantId: string | null = null;

  @Input()
  public restaurantBranchId: string | null = null;

  @Input()
  public parentMenuId: string | null = null;

  public menu: MenuEntity | null = null;
  public selectedMenu: MenuEntity | null = null; // cloning purpose to create new menu..
  public form: FormGroup;
  public selectedFileBLOB: any;
  public getImgKeyId: any;
  public selectItem = '';
  public btnPublishText = '';
  public files: any[] = [];

  private userProfile: ProfileModel;
  sanitizer: any;

  constructor(
    private modalController: ModalController,
    private alertController: AlertController,
    private loaderService: LoaderService,
    private menuRestControllerService: MenuRestControllerService,
    private toastController: ToastController,
    private userProfileService: UserProfileService,
    private smartDineImage: SmartdineImageRestControllerService,
  ) {
    this.createForm();
  }

  get isNew(): boolean {
    return !this.menuId;
  }

  get isImageAvailable(): boolean {
    return !!this.getFormValue('menuIconUrl');
  }

  public getFormValue(controlName: string): string {
    const control = this.form.get(controlName);
    let controlValue = null;
    if (control) {
      controlValue = control.value;
    }
    return controlValue;
  }

  public ngOnInit() {
    this.init();
  }

  public onMenuTemplatesClick() {
    this.showMenuSelectionPopup();
  }

  public onPublishClick() {
    this.alertController.create({
      header: 'SmartDine',
      subHeader: '',
      message: `Are you sure? you want to ${(this.btnPublishText === 'Published') ? 'Unpublish' : 'Publish' }  the item ?`,
      buttons: [
        {
          text: 'Yes',
          handler: () => {
            if (this.menu.menuStatus === 'DRAFT'){
              this.menu.menuStatus = 'PUBLISHED';
              this.btnPublishText = 'Published';
            } else {
              this.menu.menuStatus = 'DRAFT';
              this.btnPublishText = 'Unpublished';
            }

            if (this.form.valid) {
              this.save();
            }
          }
        },
        {
          text: 'No',
          handler: () => {
            console.log('Whatever');
          }
        }
      ]
    }).then(res => {
      res.present();
    });
  }

  public onSaveClick() {
    this.form.markAllAsTouched();
    if (this.selectedMenu) {
      this.upsertMenu({}, { templateMenuId: this.selectedMenu.menuId });
    } else if (this.form.valid) {
      this.save();
    }
  }

  public onSubmit() {
    if (this.form.valid) {
      this.save();
    }
  }

  public onCloseClick() {
    this.modalController.dismiss(null, '', MenuUpsertionComponent.id);
  }

  public onUndoClick() {
    this.form.reset();
    this.form.patchValue(this.menu);
  }

  /**
   * Method handler to be called on image file browsing/drop.
   */
   onImageSelection(event) {
    const files = event.target.files;
    if (files.length) {
      this.uploadImage(files[0]);
    }
  }

  private init() {
    this.setUserProfile();
    if (this.menuId) { // Updating existing one..
      this.setMenu();
    }
  }

  private createForm() {
    this.form = new FormGroup({
      name: new FormControl('', Validators.required),
      code: new FormControl('', Validators.required),
      description: new FormControl(''),
      template: new FormControl(false, Validators.required),
      tags: new FormControl([], Validators.required),
    });
  }

  public save(options = { shouldClosePopup: true }) {
    this.loaderService.showLoader();
    const menu = {
      menuId: null,
      restaurantId: this.restaurantId,
      restaurantBranchId: this.restaurantBranchId,
      parentMenuId: this.parentMenuId,
      childMenus: [],
      noOfChildMenus: 0,
      menuStatus: this.menu?.menuStatus || 'DRAFT',
      // orderableFromTime: '',
      // orderableToTime: '',
      // menuItemMaps: [],
    } as MenuEntity;

    if (this.menu) {
      Object.assign(menu, this.menu);
    }

    // Correcting improper child menus count..
    /*if (!Array.isArray(menu.childMenus)) {
      menu.childMenus = [];
    }
    menu.noOfChildMenus = menu.childMenus.length;*/

    if (!menu.menuStatus) {
      menu.menuStatus = 'DRAFT';
    }

    Object.assign(menu, this.form.value);

    if (this.isNew) {
      menu.createdAt = new Date();
      menu.creator = this.userProfile.userId;
    } else {
      menu.modifiedAt = new Date();
      menu.modifier = this.userProfile.userId;
    }
    this.loaderService.hideLoader();
    this.upsertMenu(menu, options);
  }

  private upsertMenu(menu: MenuEntity, options?: { shouldClosePopup?: boolean, templateMenuId?: string }) {
    const templateMenuId = options?.templateMenuId || null;
    const shouldClosePopup = options?.shouldClosePopup ?? true;

    this.menuRestControllerService.createMenus(menu, this.restaurantId, this.restaurantBranchId, templateMenuId).subscribe(
      (res: SmartDineResponseMenuEntity) => {
        const response = {} as any;
        if (this.isNew) {
          response.createdMenu = res.item;
        } else {
          response.updatedMenu = res.item;
        }
        if (shouldClosePopup) {
          this.modalController.dismiss(response, '', MenuUpsertionComponent.id);
          this.loaderService.hideLoader();
          this.showToast('Details saved successfully.');
        }
      },
      (err) => {
        this.loaderService.hideLoader();
        console.error('Unable to upsert menu!', err);
      }
    );
  }

  private setMenu() {
    this.menuRestControllerService.getMenu(this.menuId, this.restaurantId, this.restaurantBranchId).subscribe(
      (res: SmartDineResponseMenuEntity) => {
        this.menu = res.item;
        this.btnPublishText = (this.menu?.menuStatus === 'DRAFT') ? 'Unpublished' : 'Published';
        this.form.patchValue(this.menu);
      },
      (err) => {
        console.error('Unable to get menu details!', err);
      }
    );
  }

  private setUserProfile() {
    this.userProfileService.getUserProfile().subscribe({
      next: (userProfile: ProfileModel) => {
        this.userProfile = userProfile;
      }
    });
  }

  private async showMenuSelectionPopup() {
    const modal = await this.modalController.create({
      component: MenuSelectionComponent,
      id: MenuSelectionComponent.id,
      componentProps: {
        restaurantId: this.restaurantId,
        restaurantBranchId: this.restaurantBranchId,
      }
    });
    modal.onDidDismiss().then((res) => {
      this.selectedMenu = res?.data?.selectedMenu || null as MenuEntity;
      if (this.selectedMenu) {
        this.form.patchValue(this.selectedMenu);
        this.form.disable();
      }
    });
    await modal.present();
  }

  private async showToast(message: string) {
    const toast = await this.toastController.create({
      message,
      duration: 2000
    });
    toast.present();
  }

  /**
   * Upload Image.
   * @param file Image File.
   */
  uploadImage(imageFile: any) {
    const fileKey = this.menu?.menuIconImage?.key;
    let uploadRequest = this.smartDineImage.uploadFileForm(imageFile, this.restaurantId, this.restaurantBranchId, this.menuId);
    if (fileKey) {
      this.loaderService.showLoader();
      uploadRequest = this.smartDineImage.updateFileForm(fileKey, imageFile, this.restaurantId, this.restaurantBranchId, this.menuId);
    }

    this.loaderService.showLoader();
    uploadRequest.subscribe(
      (res) => {
        console.info('Image uploaded successfully!');
        this.menu.menuIconImage = res.item;
        this.loaderService.hideLoader();
      },
      (err) => {
        console.error('Unable to upload image!', err);
        this.loaderService.hideLoader();
      }
    );
  }
}
