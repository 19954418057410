import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-base',
  template: `
    <div>base works!</div>
  `
})
export class BaseComponent implements OnInit {

  constructor() { }

  public ngOnInit() { }

  public ionViewWillEnter() { }

}
