/**
 * SmartdineNow API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ParentOrderStatusUpdateResponse { 
    restaurantId?: string;
    restaurantBranchId?: string;
    parentOrderId?: string;
    currentOrderPaymentStatus?: ParentOrderStatusUpdateResponse.CurrentOrderPaymentStatusEnum;
    prevOrderPaymentStatus?: ParentOrderStatusUpdateResponse.PrevOrderPaymentStatusEnum;
}
export namespace ParentOrderStatusUpdateResponse {
    export type CurrentOrderPaymentStatusEnum = 'PAY_LATER' | 'PAYMENT_INITIATED' | 'PAYMENT_SUCCESS' | 'PAYMENT_REFUNDED' | 'PAYMENT_FAILED' | 'PAID' | 'REFUND_INITIATED' | 'REFUND_SUCCESS' | 'PAYMENT_NA' | 'PAY_BY_CASH' | 'PAID_BY_CASH';
    export const CurrentOrderPaymentStatusEnum = {
        PAYLATER: 'PAY_LATER' as CurrentOrderPaymentStatusEnum,
        PAYMENTINITIATED: 'PAYMENT_INITIATED' as CurrentOrderPaymentStatusEnum,
        PAYMENTSUCCESS: 'PAYMENT_SUCCESS' as CurrentOrderPaymentStatusEnum,
        PAYMENTREFUNDED: 'PAYMENT_REFUNDED' as CurrentOrderPaymentStatusEnum,
        PAYMENTFAILED: 'PAYMENT_FAILED' as CurrentOrderPaymentStatusEnum,
        PAID: 'PAID' as CurrentOrderPaymentStatusEnum,
        REFUNDINITIATED: 'REFUND_INITIATED' as CurrentOrderPaymentStatusEnum,
        REFUNDSUCCESS: 'REFUND_SUCCESS' as CurrentOrderPaymentStatusEnum,
        PAYMENTNA: 'PAYMENT_NA' as CurrentOrderPaymentStatusEnum,
        PAYBYCASH: 'PAY_BY_CASH' as CurrentOrderPaymentStatusEnum,
        PAIDBYCASH: 'PAID_BY_CASH' as CurrentOrderPaymentStatusEnum
    };
    export type PrevOrderPaymentStatusEnum = 'PAY_LATER' | 'PAYMENT_INITIATED' | 'PAYMENT_SUCCESS' | 'PAYMENT_REFUNDED' | 'PAYMENT_FAILED' | 'PAID' | 'REFUND_INITIATED' | 'REFUND_SUCCESS' | 'PAYMENT_NA' | 'PAY_BY_CASH' | 'PAID_BY_CASH';
    export const PrevOrderPaymentStatusEnum = {
        PAYLATER: 'PAY_LATER' as PrevOrderPaymentStatusEnum,
        PAYMENTINITIATED: 'PAYMENT_INITIATED' as PrevOrderPaymentStatusEnum,
        PAYMENTSUCCESS: 'PAYMENT_SUCCESS' as PrevOrderPaymentStatusEnum,
        PAYMENTREFUNDED: 'PAYMENT_REFUNDED' as PrevOrderPaymentStatusEnum,
        PAYMENTFAILED: 'PAYMENT_FAILED' as PrevOrderPaymentStatusEnum,
        PAID: 'PAID' as PrevOrderPaymentStatusEnum,
        REFUNDINITIATED: 'REFUND_INITIATED' as PrevOrderPaymentStatusEnum,
        REFUNDSUCCESS: 'REFUND_SUCCESS' as PrevOrderPaymentStatusEnum,
        PAYMENTNA: 'PAYMENT_NA' as PrevOrderPaymentStatusEnum,
        PAYBYCASH: 'PAY_BY_CASH' as PrevOrderPaymentStatusEnum,
        PAIDBYCASH: 'PAID_BY_CASH' as PrevOrderPaymentStatusEnum
    };
}