import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  private messageSource = new BehaviorSubject('messageSource');
  private currentMessages = this.messageSource.asObservable();

  constructor() { }

  showMessage(message: string) {
    this.messageSource.next(message)
  }

  public getCurrentMessages() {
    return this.currentMessages;
  }
}
