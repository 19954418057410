/**
 * SmartdineNow API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ItemQuantity { 
    measurementUnit?: ItemQuantity.MeasurementUnitEnum;
    value?: string;
}
export namespace ItemQuantity {
    export type MeasurementUnitEnum = 'MILLI_LITERS' | 'LITERS' | 'KILO_LITERS' | 'PINT' | 'GALLON' | 'MILLI_GRAMS' | 'GRAMS' | 'KILO_GRAMS' | 'TONNE' | 'SMALL' | 'LARGE' | 'HALF' | 'FULL';
    export const MeasurementUnitEnum = {
        MILLILITERS: 'MILLI_LITERS' as MeasurementUnitEnum,
        LITERS: 'LITERS' as MeasurementUnitEnum,
        KILOLITERS: 'KILO_LITERS' as MeasurementUnitEnum,
        PINT: 'PINT' as MeasurementUnitEnum,
        GALLON: 'GALLON' as MeasurementUnitEnum,
        MILLIGRAMS: 'MILLI_GRAMS' as MeasurementUnitEnum,
        GRAMS: 'GRAMS' as MeasurementUnitEnum,
        KILOGRAMS: 'KILO_GRAMS' as MeasurementUnitEnum,
        TONNE: 'TONNE' as MeasurementUnitEnum,
        SMALL: 'SMALL' as MeasurementUnitEnum,
        LARGE: 'LARGE' as MeasurementUnitEnum,
        HALF: 'HALF' as MeasurementUnitEnum,
        FULL: 'FULL' as MeasurementUnitEnum
    };
}