import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-shareoptions',
  templateUrl: './shareoptions.component.html',
  styleUrls: ['./shareoptions.component.scss'],
})
export class ShareoptionsComponent implements OnInit {


  public static readonly id = 'share=options-modal';
  private toEmailIds: String;
  private toPhoneNumbers: String;


  constructor(private modalController: ModalController) { }

  ngOnInit() {}

  public onSendClick() {

    let emailIds = [];
    let phoneNumbers = [];

    this.toEmailIds.split(",").forEach((emailId) => {
      if (emailId.length > 0) {
        emailIds.push(emailId);
      }
    });

    this.toPhoneNumbers.split(",").forEach((phoneNumber) => {
      if (phoneNumber.length > 0) {
        phoneNumbers.push(phoneNumber);
      }
    });

    this.modalController.dismiss({toEmailIds: emailIds, toPhoneNumbers: phoneNumbers}, '', ShareoptionsComponent.id);
  }

  public onCancelClick() {
    this.modalController.dismiss({}, '', ShareoptionsComponent.id);
  }

}
