/**
 * SmartdineNow API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CurrencyModel } from './currencyModel';
import { GeoLocation } from './geoLocation';
// import { RestaurantModelPrefTimeZone } from './restaurantModelPrefTimeZone';
// import { SmartDineImage } from './smartDineImage';

export interface RestaurantAdminModel { 
    restaurantId?: string;
    restaurantCode?: string;
    restaurantName?: string;
    restaurantDescription?: string;
    fssai?: string;
    gstId?: string;
    prefTimeZone?: string;
    prefTimeZoneId?: string;
    restaurantStatus?: RestaurantAdminModel.RestaurantStatusEnum;
    restaurantLogoUrl?: string;
    prefCurrencyCode?: string;
    prefCurrency?: CurrencyModel;
    maxNoOfTables?: number;
    maxNoOfRestaurantBranches?: number;

    restaurantBranchId?:string;
    restaurantBranchName?: string;
    restaurantBranchCode?: string;
    restaurantBranchAddress?: string;
    allowedOrderTypes?: Array<RestaurantAdminModel.AllowedOrderTypesEnum>;
    allowedPaymentPreferences?: Array<RestaurantAdminModel.AllowedPaymentPreferencesEnum>;
    restaurantBranchCity?:string;
    restaurantBranchContactNo?:string;
    restaurantCountry?:string;
    restaurantBranchCountryCode?:string;
    restaurantBranchDescription?:string;
    restaurantBranchEmailId?:string;
    restaurantBranchFssai?:string;
    restaurantBranchGstId?:string;
    geoLocation?:GeoLocation;
    restaurantBranchState?:string;
    restaurantBranchWebsite?:string;

    restaurantAdminUserId?:string;
    restaurantSubscriptionEntitiy?: string;
    
}
export namespace RestaurantAdminModel {
    export type RestaurantStatusEnum = 'DRAFT' | 'PENDING_APPROVAL' | 'APPROVED' | 'REJECTED' | 'PUBLISHED' | 'UNPUBLISHED' | 'PAYMENT_PENDING';
    export const RestaurantStatusEnum = {
        DRAFT: 'DRAFT' as RestaurantStatusEnum,
        PENDINGAPPROVAL: 'PENDING_APPROVAL' as RestaurantStatusEnum,
        APPROVED: 'APPROVED' as RestaurantStatusEnum,
        REJECTED: 'REJECTED' as RestaurantStatusEnum,
        PUBLISHED: 'PUBLISHED' as RestaurantStatusEnum,
        UNPUBLISHED: 'UNPUBLISHED' as RestaurantStatusEnum,
        PAYMENTPENDING: 'PAYMENT_PENDING' as RestaurantStatusEnum
    };
    export type AllowedOrderTypesEnum = 'DINE_IN' | 'DELIVERY' | 'TAKEAWAY';
    export const AllowedOrderTypesEnum = {
        DINE_IN: 'DINE_IN' as AllowedOrderTypesEnum,
        DELIVERY: 'DELIVERY' as AllowedOrderTypesEnum,
        TAKEAWAY: 'TAKEAWAY' as AllowedOrderTypesEnum
    };
    export type AllowedPaymentPreferencesEnum = 'PAY_BY_ONLINE' | 'PAY_BY_CASH';
    export const AllowedPaymentPreferencesEnum = {
        PAY_BY_ONLINE: 'PAY_BY_ONLINE' as AllowedPaymentPreferencesEnum,
        PAY_BY_CASH: 'PAY_BY_CASH' as AllowedPaymentPreferencesEnum
    };
}
