import {Component, Input, OnChanges, SimpleChanges, OnInit,} from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CartItemModel, MenuModel, MenuUsersRestControllerService, SmartDineResponseMenuItemMapModel, SmartDineResponseMenuModel,} from 'src/app/swagger';
import { MenuItemMapping } from '../../models/menu-item-mapping/menu-item-mapping.model';
import { CartService } from '../../services/cart/cart.service';
import { AdminCartService } from '../../services/admincart/admincart.service';
import { QuantityChangeEvent } from '../quantity-selector/quantity-selector.component';
import { UserProfileService } from '../../services/user-profile/user-profile.service';
import { ProfileModel } from '../../../swagger';
import { Role } from 'src/app/common/enums/role.enum';
import { RedirectUserService } from 'src/app/swagger/api/redirectUser.service';
import { Router } from '@angular/router';
import { CatalogSideMenusComponent } from './catalog-side-menus/catalog-side-menus.component';

interface MenuItemMappingsDictionary {
  [key: string]: MenuItemMapping;
}

interface Menu extends MenuModel {
  menuId?: string;
  itemsMappings?: MenuItemMapping[];
}

interface SdEvent extends MouseEvent {
  value: any;
}

@Component({
  selector: 'app-sd-catalog',
  templateUrl: './catalog.component.html',
  styleUrls: ['./catalog.component.scss'],
})
export class CatalogComponent implements OnChanges, OnInit {

  @Input() public catalogDetails: any = null;
  
  public menus: Menu[] | any = [];
  public itemMappings: MenuItemMappingsDictionary = {};
  public selectedMenu: MenuModel | null = null;

  private cartService: CartService | AdminCartService | null = null;
  public isUserLoggedIn: boolean = false;
  public userProfile: ProfileModel | null = null;
  public filteredItems: any[] = [];
  public specialNote: string[] = [];

  constructor(
    private menuUsersRestControllerService: MenuUsersRestControllerService,
    private modalController: ModalController,
    private userProfileService: UserProfileService,
    private userCartService: CartService,
    private adminCartService: AdminCartService,
    private router: Router,
    private redirectUserService: RedirectUserService,
  ) {
    this.getUserDetails();
  }

  ngOnInit(): void {
    this.setUrlParams();
    this.init();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes && changes?.['catalogDetails']?.currentValue) {
      this.init();
    }
  }

  public onMenuListClick() {
    this.showMenus();
  }

  public onMenuClick(event: SdEvent): void {
    this.selectedMenu = event.value as MenuModel;
    this.scrollToMenu(this.selectedMenu);
  }

  public onQuantityChange(event: QuantityChangeEvent, item: MenuItemMapping | any): void {
    const cartItem = item.cartItem;
    const quantity = event.value;
    cartItem.quantity = quantity;
    cartItem.price = cartItem.unitPrice * quantity;
    if (quantity === 0 && this.cartService != null) {
      this.cartService.removeItem(cartItem);
      this.removeNoteForItem(item.itemId)
    } else {
      this.specialNote[item.itemId] = this.specialNote[item.itemId] ?? '';
      cartItem['note'] = this.specialNote[item?.itemId];
      this.cartService?.setItem(cartItem);
    }
  }

  private removeNoteForItem(itemId: string) {
    if (this.specialNote && this.specialNote[itemId]) {
      delete this.specialNote[itemId];
    }
  }

  public onAppear(_event: Event, menu: MenuModel) {
    this.selectedMenu = menu;
  }

  private init() {
    this.isUserLoggedIn = this.userProfile && this.userProfile?.userId ? true : false;

    if (this.catalogDetails?.restaurantId && this.catalogDetails?.restaurantBranchId) {
      this.setMenus();
    }

    this.setUrlParams();
  }

  private setMenus() {
    const storageKey = `menus_${this.catalogDetails?.restaurantId}_${this.catalogDetails?.restaurantBranchId}`;
    const cachedMenus = this.loadMenusFromStorage(storageKey);

    if (cachedMenus) {
      this.processMenus(cachedMenus, false);
    } else {
      this.fetchMenusFromAPI(storageKey);
    }
  }
  
  private loadMenusFromStorage(storageKey: string): Menu[] | null {
    const cachedData = sessionStorage.getItem(storageKey);
    return cachedData ? JSON.parse(cachedData) : null;
  }
  
  private fetchMenusFromAPI(storageKey: string) {
    this.menus = [];
    this.itemMappings = {};
  
    this.menuUsersRestControllerService
      .getTopLevelMenus(this.catalogDetails?.restaurantId, this.catalogDetails?.restaurantBranchId)
      .subscribe({
        next: (res: SmartDineResponseMenuModel) => {
          this.menus = res.items;
          this.loadItemsMappingsForMenus(this.menus, storageKey);
        },
        error: (err) => {
          console.error('Unable to get menus info!', err);
        },
      });
  }
  
  private loadItemsMappingsForMenus(menus: Menu[], storageKey: string) {
    const requests = menus.map(menu =>
      this.menuUsersRestControllerService
        .getMenuItemMaps(this.catalogDetails?.restaurantId, this.catalogDetails?.restaurantBranchId, menu.menuId)
        .toPromise()
        .then((res: SmartDineResponseMenuItemMapModel) => {
          menu.itemsMappings = res.items.map((e) => MenuItemMapping.create(e));

          this.updateItemMappings(menu.itemsMappings);
        })
        .catch((err) => {
          console.error(`Unable to get items for menu ${menu.menuId}`, err);
          menu.itemsMappings = [];
        })
    );
  
    Promise.all(requests).then(() => {
      sessionStorage.setItem(storageKey, JSON.stringify(menus));
      this.processMenus(menus, false);
    });
  }
  
  private processMenus(menus: Menu[], fetchItems: boolean) {
    this.menus = menus;
    this.menus.forEach((each, index) => {
      if (index === 0) {
        this.selectedMenu = each;
      }
      this.initializeItemsMappings(each, fetchItems);
    });
    this.filteredItems = this.menus;
  }

  private initializeItemsMappings(menu: Menu, fetchItems: boolean) {
    menu.itemsMappings = menu.itemsMappings || [];
  
    if (!fetchItems) {
      this.updateItemMappings(menu.itemsMappings);
    } else if (fetchItems && menu.itemsMappings.length === 0) {
      this.menuUsersRestControllerService
        .getMenuItemMaps(this.catalogDetails?.restaurantId, this.catalogDetails?.restaurantBranchId, menu.menuId)
        .subscribe({
          next: (res: SmartDineResponseMenuItemMapModel) => {
            menu.itemsMappings = res.items.map((e) => MenuItemMapping.create(e));
            this.updateItemMappings(menu.itemsMappings);
          },
          error: (err) => {
            console.error('Unable to get menu items info!', err);
          },
        });
    }
  
    // this.subscribeToCartService();
  }
  
  private updateItemMappings(itemsMappings: any) {
    itemsMappings.forEach((each) => {
      this.itemMappings[each.menuItemMapId] = each;
      this.setMenuItemCartInfo(each);
    });
  }
  
  private subscribeToCartService() {
    if (this.cartService) {
      this.cartService.getItems().subscribe({
        next: () => {
          Object.keys(this.itemMappings).forEach((each) => {
            this.setMenuItemCartInfo(this.itemMappings[each]);
          });
        },
        error: (err) => {
          console.error('Failed to get items', err.message);
        },
      });
    }
  }

  private setMenuItemCartInfo(menuItemMap: MenuItemMapping) {
    if (this.cartService == null) {
      return;
    }
    let cartItem = this.cartService.getItem(menuItemMap?.menuItemMapId);
    if (!cartItem) {
      cartItem = {
        itemId: menuItemMap.itemId,
        menuItemMapId: menuItemMap.menuItemMapId,
        menuItemType: CartItemModel.MenuItemTypeEnum.FOOD,
        unitPrice: menuItemMap?.priceDetails?.price,
        quantity: 0,
        price: 0,
        itemName: menuItemMap.menuItem.name,
        itemDescription: menuItemMap.menuItem?.description,
      } as CartItemModel;
    }

    menuItemMap.cartItem = cartItem;
  }

  private async showMenus() {
    const modal = await this.modalController.create({
      component: CatalogSideMenusComponent,
      componentProps: {
        menus: this.menus,
        value: this.selectedMenu,
        totalCount: this.getTotalItems(),
        isMobileView: true,
      },
    })
    modal.onDidDismiss().then((data: any) => {
      const selectedMenu = data?.data?.value as MenuModel;
      if (selectedMenu) {
        this.selectedMenu = selectedMenu;
        this.scrollToMenu(this.selectedMenu);
      }
    });
    await modal.present();
  }

  private scrollToMenu(menu: MenuModel | string) {
    let target: any = null;
    if (menu === 'All') {
      target = document.getElementById('AllMenus');
    } else {
      target = document.getElementById(menu['menuId']);
    }
    target?.scrollIntoView({ behavior: 'smooth' });
  }

  private setUrlParams() {
    if (!this.isUserLoggedIn) {
      const currentUrl = this.router.url;
      const encodedUrl = encodeURIComponent(currentUrl);
      this.redirectUserService.setRedirectUrl(encodedUrl);
    }
  }

  public searchFoodItems(searchValue: string) {
    if (!searchValue?.trim()) {
      this.menus = this.filteredItems.slice();
    } else {
      const lowerSearchValue = searchValue.toLowerCase();
  
      this.menus = this.filteredItems
        .map((menu) => {
          const filteredMappings = menu.itemsMappings.filter((mapping) =>
            mapping.menuItem.name.toLowerCase().includes(lowerSearchValue)
          );
          return { ...menu, itemsMappings: filteredMappings };
        })
        .filter((menu) => menu.itemsMappings.length > 0);
    }
  }

  public getUserDetails(): void {
    this.userProfileService.getUserProfile().subscribe({
      next: (userProfile: ProfileModel) => {
        this.userProfile = userProfile;
        this.isUserLoggedIn = userProfile && userProfile?.userId ? true : false;
        if (userProfile?.roles && (userProfile?.roles.includes(Role.ADMINS) || userProfile?.roles.includes(Role.RESTO_ADMINS))) {
          this.cartService = this.adminCartService;
        } else {
          this.cartService = this.userCartService;
        }
      },
      error: (err) => {
        this.cartService = null;
      },
    });
  }

  public getTotalItems(): number {
    return this.menus?.reduce((count, menu) => 
      count + (menu?.itemsMappings?.length || 0), 
      0
    ) || 0;
  }

}
