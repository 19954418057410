import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import {
  MenuEntity,
  MenuItemEntity,
  MenuItemMapEntity,
  MenuItemMapRestControllerService,
  MenuItemRestControllerService,
  MenuRestControllerService,
  SmartDineResponseMenuEntity,
  SmartDineResponseMenuItemEntity,
  SmartDineResponseMenuItemMapEntity,
} from 'src/app/swagger';

@Component({
  selector: 'app-item-selection',
  templateUrl: './item-selection.component.html',
  styleUrls: ['./item-selection.component.scss'],
})
export class ItemSelectionComponent implements OnInit {

  public static readonly id = 'item-selection-modal';

  @Input()
  public menuId: string | null = null;

  @Input()
  public restaurantId: string | null = null;

  @Input()
  public restaurantBranchId: string | null = null;

  @Input()
  public selectedItemMaps: MenuItemMapEntity[] = [];

  public menu: MenuEntity;
  public menuItems: MenuItemEntity[];
  public form: FormGroup;

  constructor(
    private modalController: ModalController,
    private menuItemRestControllerService: MenuItemRestControllerService,
    private formBuilder: FormBuilder,
    private menuRestControllerService: MenuRestControllerService,
    private menuItemMapRestControllerService: MenuItemMapRestControllerService,
  ) { }

  get itemControls() {
    return this.form.get('items') as FormArray;
  }

  public ngOnInit() {
    this.init();
  }

  public onSaveClick() {
    this.form.markAllAsTouched();
    const values = this.itemControls.value;
    const selectedItems = this.menuItems.filter((item, i) => values[i] && !this.selectedItemMaps.find(each => each.itemId === item.itemId));
    const deselectedItems = this.menuItems.filter((item, i) => !values[i] && this.selectedItemMaps.find(each => each.itemId === item.itemId));
    const deselectedItemMaps = deselectedItems.map((item) => this.selectedItemMaps.find(each => each.itemId === item.itemId));

    Promise.all([
      this.createMappings(selectedItems),
      this.deleteMappings(deselectedItemMaps),
    ]).then((res) => {
      this.modalController.dismiss({
        createdMappings: (res[0] as SmartDineResponseMenuItemMapEntity[]).map(e => e.item),
        deletedMappings: deselectedItemMaps,
      }, '', ItemSelectionComponent.id);
    });
  }

  public onCloseClick() {
    this.modalController.dismiss(null, '', ItemSelectionComponent.id);
  }

  public onItemClick(_event: MouseEvent, _item: MenuItemEntity, index: number) {
    const control = this.itemControls.at(index);
    control.setValue(!control.value);
  }

  private init() {
    if (this.menuId) {
      this.setMenu();
    }
    this.setMenuItems();
  }

  private setMenu() {
    this.menuRestControllerService.getMenu(this.menuId, this.restaurantId, this.restaurantBranchId).subscribe({
      next: (res: SmartDineResponseMenuEntity) => {
        this.menu = res.item;
      }
    });
  }

  private setMenuItems() {
    this.menuItems = [];
    const restaurantBranchId = null; // TODO: Need Publish functionality
    this.menuItemRestControllerService.getMenuItems(this.restaurantId, restaurantBranchId).subscribe({
      next: (res: SmartDineResponseMenuItemEntity) => {
        this.menuItems = res.items;
        this.createForm();
      },
      error: (err) => {
        console.error('Unable to get menu items!', err);
      }
    });
  }

  private createForm() {
    const controls = this.menuItems.map((item) => {
      const isSelected = !!this.selectedItemMaps.find(each => each.itemId === item.itemId);
      const control = this.formBuilder.control(isSelected);
      return control;
    });

    this.form = new FormGroup({
      items: this.formBuilder.array(controls)
    });
  }

  private async createMappings(items: MenuItemEntity[]) {
    const promises = items.map((item) => {
      const mapping = {
        menuItemMapId: null,
        restaurantId: this.restaurantId,
        restaurantBranchId: this.restaurantBranchId,
        itemId: item.itemId,
        menuEntity: this.menu,
        menuId: this.menuId,
        menuItemEntity: item
      } as MenuItemMapEntity;

      return this.menuItemMapRestControllerService.createMenuItemMaps(
        mapping, this.restaurantId, this.restaurantBranchId
      ).toPromise().catch((err) => {
        console.error('Unable to create mapping for', this.menu.name, item.name, err);
      });
    });

    return Promise.all(promises);
  }

  private async deleteMappings(items: MenuItemMapEntity[]) {
    const promises = items.map((item) => {
      return this.menuItemMapRestControllerService.deleteMenuItemMap(
        item.menuItemMapId, this.restaurantId, this.restaurantBranchId
      ).toPromise().catch((err) => {
        console.error('Unable to delete mapping for', this.menu.name, item.menuItemEntity.name, err);
      });
    });

    return Promise.all(promises);
  }
}
