import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
})
export class LogoComponent implements OnInit {

  public customIconLocation: string = null;

  constructor() {
    const parsedUrl = new URL(window.location.href);
    if (parsedUrl && (parsedUrl.hostname == 'dev-chariot.smartdinenow.com' || parsedUrl.hostname == 'chariot.smartdinenow.com')) {
      this.customIconLocation = 'assets/img/chariotLogo.png'
    }
  }

  ngOnInit() {}

}
