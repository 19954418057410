/**
 * SmartdineNow API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CategoryProductMapModel } from '../model/categoryProductMapModel';
import { SmartDineResponseCategoryProductMapModel } from '../model/smartDineResponseCategoryProductMapModel';
import { SmartDineResponseListCategoryProductMapModel } from '../model/smartDineResponseListCategoryProductMapModel';
import { SortByParam } from '../model/sortByParam';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class CategoryProductMapRestControllerService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createCategoryProductMaps(body: CategoryProductMapModel, observe?: 'body', reportProgress?: boolean): Observable<SmartDineResponseCategoryProductMapModel>;
    public createCategoryProductMaps(body: CategoryProductMapModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SmartDineResponseCategoryProductMapModel>>;
    public createCategoryProductMaps(body: CategoryProductMapModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SmartDineResponseCategoryProductMapModel>>;
    public createCategoryProductMaps(body: CategoryProductMapModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling createCategoryProductMaps.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<SmartDineResponseCategoryProductMapModel>('post',`${this.basePath}/api/admin/categoryproductmaps`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param categoryProductMapId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteCategoryProductMap(categoryProductMapId: string, observe?: 'body', reportProgress?: boolean): Observable<SmartDineResponseCategoryProductMapModel>;
    public deleteCategoryProductMap(categoryProductMapId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SmartDineResponseCategoryProductMapModel>>;
    public deleteCategoryProductMap(categoryProductMapId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SmartDineResponseCategoryProductMapModel>>;
    public deleteCategoryProductMap(categoryProductMapId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (categoryProductMapId === null || categoryProductMapId === undefined) {
            throw new Error('Required parameter categoryProductMapId was null or undefined when calling deleteCategoryProductMap.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<SmartDineResponseCategoryProductMapModel>('delete',`${this.basePath}/api/admin/categoryproductmaps/${encodeURIComponent(String(categoryProductMapId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param categoryProductMapId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCategoryProductMap(categoryProductMapId: string, observe?: 'body', reportProgress?: boolean): Observable<SmartDineResponseCategoryProductMapModel>;
    public getCategoryProductMap(categoryProductMapId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SmartDineResponseCategoryProductMapModel>>;
    public getCategoryProductMap(categoryProductMapId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SmartDineResponseCategoryProductMapModel>>;
    public getCategoryProductMap(categoryProductMapId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (categoryProductMapId === null || categoryProductMapId === undefined) {
            throw new Error('Required parameter categoryProductMapId was null or undefined when calling getCategoryProductMap.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<SmartDineResponseCategoryProductMapModel>('get',`${this.basePath}/api/admin/categoryproductmaps/${encodeURIComponent(String(categoryProductMapId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param categoryId 
     * @param pageNo 
     * @param pageSize 
     * @param sortByParams 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCategoryProductMaps(categoryId: string, pageNo?: number, pageSize?: number, sortByParams?: Array<SortByParam>, observe?: 'body', reportProgress?: boolean): Observable<SmartDineResponseListCategoryProductMapModel>;
    public getCategoryProductMaps(categoryId: string, pageNo?: number, pageSize?: number, sortByParams?: Array<SortByParam>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SmartDineResponseListCategoryProductMapModel>>;
    public getCategoryProductMaps(categoryId: string, pageNo?: number, pageSize?: number, sortByParams?: Array<SortByParam>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SmartDineResponseListCategoryProductMapModel>>;
    public getCategoryProductMaps(categoryId: string, pageNo?: number, pageSize?: number, sortByParams?: Array<SortByParam>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (categoryId === null || categoryId === undefined) {
            throw new Error('Required parameter categoryId was null or undefined when calling getCategoryProductMaps.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (categoryId !== undefined && categoryId !== null) {
            queryParameters = queryParameters.set('categoryId', <any>categoryId);
        }
        if (pageNo !== undefined && pageNo !== null) {
            queryParameters = queryParameters.set('pageNo', <any>pageNo);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('pageSize', <any>pageSize);
        }
        if (sortByParams) {
            sortByParams.forEach((element) => {
                queryParameters = queryParameters.append('sortByParams', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<SmartDineResponseListCategoryProductMapModel>('get',`${this.basePath}/api/admin/categoryproductmaps`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param categoryProductMapId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateCategoryProductMap(body: CategoryProductMapModel, categoryProductMapId: string, observe?: 'body', reportProgress?: boolean): Observable<SmartDineResponseCategoryProductMapModel>;
    public updateCategoryProductMap(body: CategoryProductMapModel, categoryProductMapId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SmartDineResponseCategoryProductMapModel>>;
    public updateCategoryProductMap(body: CategoryProductMapModel, categoryProductMapId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SmartDineResponseCategoryProductMapModel>>;
    public updateCategoryProductMap(body: CategoryProductMapModel, categoryProductMapId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateCategoryProductMap.');
        }

        if (categoryProductMapId === null || categoryProductMapId === undefined) {
            throw new Error('Required parameter categoryProductMapId was null or undefined when calling updateCategoryProductMap.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<SmartDineResponseCategoryProductMapModel>('put',`${this.basePath}/api/admin/categoryproductmaps/${encodeURIComponent(String(categoryProductMapId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
