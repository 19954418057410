import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { ProfileModel } from 'src/app/swagger';
import { UserProfileService } from '../../services/user-profile/user-profile.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-profile-popover',
  templateUrl: './profile-popover.component.html',
  styleUrls: ['./profile-popover.component.scss'],
})
export class ProfilePopoverComponent implements OnInit {

  public static readonly id = 'profile-options-modal';

  public userProfile: ProfileModel | null = null;
  public dashboardLink: string | null = null;
  public userType: string | null = null
  private currentPopover: HTMLIonPopoverElement | null = null;

  constructor(
    private popoverController: PopoverController,
    private authService: AuthService,
    private userProfileService: UserProfileService,
    private router: Router,
  ) { }

  public onItemClick(): void {
    if(this.userType === 'Users'){
      this.router.navigate(['/customer/user-profile']);
    }else if(this.userType === 'RestaurantAdmins'){
      this.router.navigate(['/resto-admin/user-profile']);
    }else if(this.userType === 'Admins'){
      this.router.navigate(['/admin/user-profile']);
    }
    this.popoverController.dismiss('', '', ProfilePopoverComponent.id);
  }

  public onDashboardClick(_: Event) {
    this.router.navigateByUrl(this.dashboardLink);
    this.popoverController.dismiss('', '', ProfilePopoverComponent.id);
  }

  public onLogoutClick(_: MouseEvent) {
    this.authService.clearSession();
    this.popoverController.dismiss("logout", "", ProfilePopoverComponent.id);
    this.userProfileService.setUserProfile(null);
    this.router.navigateByUrl('/');
  }

  public onSwitchClick(event:Event){
    event.preventDefault();
    event.stopPropagation();
    this.authService.clearSession();
    this.popoverController.dismiss("switch", "", ProfilePopoverComponent.id);
    this.router.navigateByUrl('/login');
  }

  public ngOnInit() {
    this.init();
  }

  private init() {
    this.setUserProfile();
  }

  private setUserProfile() {
    this.userProfile = null;
    this.userProfileService.getUserProfile().subscribe({
      next: (userProfile: ProfileModel) => {
        this.userProfile = userProfile;
        if(userProfile){
          this.userType = this.userProfile.roles[0];
          this.updateDashboardLink(this.userType);
        }
      }
    });
  }

  public updateDashboardLink(user_type:string){
    if(user_type === 'Users'){
      this.dashboardLink = '/customer/home';
    }else if(user_type === 'RestaurantAdmins'){
      this.dashboardLink = '/resto-admin/home';
    }else if(user_type === 'Admins'){
      this.dashboardLink = '/admin/home';
    }
  }

}
