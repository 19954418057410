/**
 * SmartdineNow API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { MenuItemMapEntity } from '../model/menuItemMapEntity';
import { SmartDineResponseLong } from '../model/smartDineResponseLong';
import { SmartDineResponseMenuItemMapEntity } from '../model/smartDineResponseMenuItemMapEntity';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class MenuItemMapRestControllerService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param body 
     * @param restaurantId 
     * @param restaurantBranchId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createMenuItemMaps(body: MenuItemMapEntity, restaurantId?: string, restaurantBranchId?: string, observe?: 'body', reportProgress?: boolean): Observable<SmartDineResponseMenuItemMapEntity>;
    public createMenuItemMaps(body: MenuItemMapEntity, restaurantId?: string, restaurantBranchId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SmartDineResponseMenuItemMapEntity>>;
    public createMenuItemMaps(body: MenuItemMapEntity, restaurantId?: string, restaurantBranchId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SmartDineResponseMenuItemMapEntity>>;
    public createMenuItemMaps(body: MenuItemMapEntity, restaurantId?: string, restaurantBranchId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling createMenuItemMaps.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (restaurantId !== undefined && restaurantId !== null) {
            queryParameters = queryParameters.set('restaurantId', <any>restaurantId);
        }
        if (restaurantBranchId !== undefined && restaurantBranchId !== null) {
            queryParameters = queryParameters.set('restaurantBranchId', <any>restaurantBranchId);
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<SmartDineResponseMenuItemMapEntity>('post',`${this.basePath}/api/admin/menuitemmaps`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param menuItemMapId 
     * @param restaurantId 
     * @param restaurantBranchId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteMenuItemMap(menuItemMapId: string, restaurantId?: string, restaurantBranchId?: string, observe?: 'body', reportProgress?: boolean): Observable<SmartDineResponseMenuItemMapEntity>;
    public deleteMenuItemMap(menuItemMapId: string, restaurantId?: string, restaurantBranchId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SmartDineResponseMenuItemMapEntity>>;
    public deleteMenuItemMap(menuItemMapId: string, restaurantId?: string, restaurantBranchId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SmartDineResponseMenuItemMapEntity>>;
    public deleteMenuItemMap(menuItemMapId: string, restaurantId?: string, restaurantBranchId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (menuItemMapId === null || menuItemMapId === undefined) {
            throw new Error('Required parameter menuItemMapId was null or undefined when calling deleteMenuItemMap.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (restaurantId !== undefined && restaurantId !== null) {
            queryParameters = queryParameters.set('restaurantId', <any>restaurantId);
        }
        if (restaurantBranchId !== undefined && restaurantBranchId !== null) {
            queryParameters = queryParameters.set('restaurantBranchId', <any>restaurantBranchId);
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<SmartDineResponseMenuItemMapEntity>('delete',`${this.basePath}/api/admin/menuitemmaps/${encodeURIComponent(String(menuItemMapId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param menuItemMapId 
     * @param restaurantId 
     * @param restaurantBranchId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMenuItemMap(menuItemMapId: string, restaurantId?: string, restaurantBranchId?: string, observe?: 'body', reportProgress?: boolean): Observable<SmartDineResponseMenuItemMapEntity>;
    public getMenuItemMap(menuItemMapId: string, restaurantId?: string, restaurantBranchId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SmartDineResponseMenuItemMapEntity>>;
    public getMenuItemMap(menuItemMapId: string, restaurantId?: string, restaurantBranchId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SmartDineResponseMenuItemMapEntity>>;
    public getMenuItemMap(menuItemMapId: string, restaurantId?: string, restaurantBranchId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (menuItemMapId === null || menuItemMapId === undefined) {
            throw new Error('Required parameter menuItemMapId was null or undefined when calling getMenuItemMap.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (restaurantId !== undefined && restaurantId !== null) {
            queryParameters = queryParameters.set('restaurantId', <any>restaurantId);
        }
        if (restaurantBranchId !== undefined && restaurantBranchId !== null) {
            queryParameters = queryParameters.set('restaurantBranchId', <any>restaurantBranchId);
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<SmartDineResponseMenuItemMapEntity>('get',`${this.basePath}/api/admin/menuitemmaps/${encodeURIComponent(String(menuItemMapId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param menuId 
     * @param restaurantId 
     * @param restaurantBranchId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMenuItemMaps1(menuId: string, restaurantId?: string, restaurantBranchId?: string, observe?: 'body', reportProgress?: boolean): Observable<SmartDineResponseMenuItemMapEntity>;
    public getMenuItemMaps1(menuId: string, restaurantId?: string, restaurantBranchId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SmartDineResponseMenuItemMapEntity>>;
    public getMenuItemMaps1(menuId: string, restaurantId?: string, restaurantBranchId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SmartDineResponseMenuItemMapEntity>>;
    public getMenuItemMaps1(menuId: string, restaurantId?: string, restaurantBranchId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (menuId === null || menuId === undefined) {
            throw new Error('Required parameter menuId was null or undefined when calling getMenuItemMaps1.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (restaurantId !== undefined && restaurantId !== null) {
            queryParameters = queryParameters.set('restaurantId', <any>restaurantId);
        }
        if (restaurantBranchId !== undefined && restaurantBranchId !== null) {
            queryParameters = queryParameters.set('restaurantBranchId', <any>restaurantBranchId);
        }
        if (menuId !== undefined && menuId !== null) {
            queryParameters = queryParameters.set('menuId', <any>menuId);
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<SmartDineResponseMenuItemMapEntity>('get',`${this.basePath}/api/admin/menuitemmaps`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param menuId 
     * @param restaurantId 
     * @param restaurantBranchId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMenuItemsCount(menuId: string, restaurantId?: string, restaurantBranchId?: string, observe?: 'body', reportProgress?: boolean): Observable<SmartDineResponseLong>;
    public getMenuItemsCount(menuId: string, restaurantId?: string, restaurantBranchId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SmartDineResponseLong>>;
    public getMenuItemsCount(menuId: string, restaurantId?: string, restaurantBranchId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SmartDineResponseLong>>;
    public getMenuItemsCount(menuId: string, restaurantId?: string, restaurantBranchId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (menuId === null || menuId === undefined) {
            throw new Error('Required parameter menuId was null or undefined when calling getMenuItemsCount.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (restaurantId !== undefined && restaurantId !== null) {
            queryParameters = queryParameters.set('restaurantId', <any>restaurantId);
        }
        if (restaurantBranchId !== undefined && restaurantBranchId !== null) {
            queryParameters = queryParameters.set('restaurantBranchId', <any>restaurantBranchId);
        }
        if (menuId !== undefined && menuId !== null) {
            queryParameters = queryParameters.set('menuId', <any>menuId);
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<SmartDineResponseLong>('get',`${this.basePath}/api/admin/menuitemmaps/count`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param menuItemMapId 
     * @param restaurantId 
     * @param restaurantBranchId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateMenuItemMap(body: MenuItemMapEntity, menuItemMapId: string, restaurantId?: string, restaurantBranchId?: string, observe?: 'body', reportProgress?: boolean): Observable<SmartDineResponseMenuItemMapEntity>;
    public updateMenuItemMap(body: MenuItemMapEntity, menuItemMapId: string, restaurantId?: string, restaurantBranchId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SmartDineResponseMenuItemMapEntity>>;
    public updateMenuItemMap(body: MenuItemMapEntity, menuItemMapId: string, restaurantId?: string, restaurantBranchId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SmartDineResponseMenuItemMapEntity>>;
    public updateMenuItemMap(body: MenuItemMapEntity, menuItemMapId: string, restaurantId?: string, restaurantBranchId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateMenuItemMap.');
        }

        if (menuItemMapId === null || menuItemMapId === undefined) {
            throw new Error('Required parameter menuItemMapId was null or undefined when calling updateMenuItemMap.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (restaurantId !== undefined && restaurantId !== null) {
            queryParameters = queryParameters.set('restaurantId', <any>restaurantId);
        }
        if (restaurantBranchId !== undefined && restaurantBranchId !== null) {
            queryParameters = queryParameters.set('restaurantBranchId', <any>restaurantBranchId);
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<SmartDineResponseMenuItemMapEntity>('put',`${this.basePath}/api/admin/menuitemmaps/${encodeURIComponent(String(menuItemMapId))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
