/**
 * SmartdineNow API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AddressModel } from './addressModel';
import { OrderItemModel } from './orderItemModel';
import { OrderOfferInfoModel } from './orderOfferInfoModel';
import { OrderPriceModel } from './orderPriceModel';
import { OrderTaxInfoModel } from './orderTaxInfoModel';
import { RestaurantBranchModel } from './restaurantBranchModel';
import { RestaurantBranchTableModel } from './restaurantBranchTableModel';
import { RestaurantModel } from './restaurantModel';
import { UserModel } from './userModel';

export interface OrderModel { 
    restaurantId?: string;
    restaurantBranchId?: string;
    tableId?: string;
    addressId?: string;
    restaurantModel?: RestaurantModel;
    restaurantBranchModel?: RestaurantBranchModel;
    restaurantBranchTableModel?: RestaurantBranchTableModel;
    cartId?: string;
    orderId?: string;
    orderNumber?: number;
    orderType?: OrderModel.OrderTypeEnum;
    noOfItems?: string;
    orderPriceModel?: OrderPriceModel;
    orderItemModels?: { [key: string]: OrderItemModel; };
    orderTaxInfoModels?: { [key: string]: OrderTaxInfoModel; };
    orderStatus?: OrderModel.OrderStatusEnum;
    nextPossibleOrderStatuses?: Array<OrderModel.NextPossibleOrderStatusesEnum>;
    createdAt?: Date;
    createdBy?: UserModel;
    parentOrderId?: string;
    parentOrderNumber?: number;
    orderPaymentStatus?: OrderModel.OrderPaymentStatusEnum;
    payLater?: boolean;
    orderOfferInfoModels?: { [key: string]: OrderOfferInfoModel; };
    paymentPreference?: OrderModel.PaymentPreferenceEnum;
    customerPhoneNo?: string;
    customerName?: string;
    customerAddressModel?: AddressModel;
    comments?: string;
    customerUserId?: string;
}
export namespace OrderModel {
    export type OrderTypeEnum = 'DINE_IN' | 'DELIVERY' | 'TAKEAWAY';
    export const OrderTypeEnum = {
        DINEIN: 'DINE_IN' as OrderTypeEnum,
        DELIVERY: 'DELIVERY' as OrderTypeEnum,
        TAKEAWAY: 'TAKEAWAY' as OrderTypeEnum
    };
    export type OrderStatusEnum = 'DRAFT' | 'ITEMS_RESERVED' | 'SUBMITTED' | 'CANCELLED' | 'DECLINED' | 'ACCEPTED' | 'PREPARING' | 'PREPARED' | 'DELIVERED' | 'NOT_ACCEPTED' | 'PICKED_UP';
    export const OrderStatusEnum = {
        DRAFT: 'DRAFT' as OrderStatusEnum,
        ITEMSRESERVED: 'ITEMS_RESERVED' as OrderStatusEnum,
        SUBMITTED: 'SUBMITTED' as OrderStatusEnum,
        CANCELLED: 'CANCELLED' as OrderStatusEnum,
        DECLINED: 'DECLINED' as OrderStatusEnum,
        ACCEPTED: 'ACCEPTED' as OrderStatusEnum,
        PREPARING: 'PREPARING' as OrderStatusEnum,
        PREPARED: 'PREPARED' as OrderStatusEnum,
        DELIVERED: 'DELIVERED' as OrderStatusEnum,
        NOTACCEPTED: 'NOT_ACCEPTED' as OrderStatusEnum,
        PICKEDUP: 'PICKED_UP' as OrderStatusEnum
    };
    export type NextPossibleOrderStatusesEnum = 'DRAFT' | 'ITEMS_RESERVED' | 'SUBMITTED' | 'CANCELLED' | 'DECLINED' | 'ACCEPTED' | 'PREPARING' | 'PREPARED' | 'DELIVERED' | 'NOT_ACCEPTED' | 'PICKED_UP';
    export const NextPossibleOrderStatusesEnum = {
        DRAFT: 'DRAFT' as NextPossibleOrderStatusesEnum,
        ITEMSRESERVED: 'ITEMS_RESERVED' as NextPossibleOrderStatusesEnum,
        SUBMITTED: 'SUBMITTED' as NextPossibleOrderStatusesEnum,
        CANCELLED: 'CANCELLED' as NextPossibleOrderStatusesEnum,
        DECLINED: 'DECLINED' as NextPossibleOrderStatusesEnum,
        ACCEPTED: 'ACCEPTED' as NextPossibleOrderStatusesEnum,
        PREPARING: 'PREPARING' as NextPossibleOrderStatusesEnum,
        PREPARED: 'PREPARED' as NextPossibleOrderStatusesEnum,
        DELIVERED: 'DELIVERED' as NextPossibleOrderStatusesEnum,
        NOTACCEPTED: 'NOT_ACCEPTED' as NextPossibleOrderStatusesEnum,
        PICKEDUP: 'PICKED_UP' as NextPossibleOrderStatusesEnum
    };
    export type OrderPaymentStatusEnum = 'PAY_LATER' | 'PAYMENT_INITIATED' | 'PAYMENT_SUCCESS' | 'PAYMENT_REFUNDED' | 'PAYMENT_FAILED' | 'PAID' | 'REFUND_INITIATED' | 'REFUND_SUCCESS' | 'PAYMENT_NA' | 'PAY_BY_CASH' | 'PAID_BY_CASH';
    export const OrderPaymentStatusEnum = {
        PAYLATER: 'PAY_LATER' as OrderPaymentStatusEnum,
        PAYMENTINITIATED: 'PAYMENT_INITIATED' as OrderPaymentStatusEnum,
        PAYMENTSUCCESS: 'PAYMENT_SUCCESS' as OrderPaymentStatusEnum,
        PAYMENTREFUNDED: 'PAYMENT_REFUNDED' as OrderPaymentStatusEnum,
        PAYMENTFAILED: 'PAYMENT_FAILED' as OrderPaymentStatusEnum,
        PAID: 'PAID' as OrderPaymentStatusEnum,
        REFUNDINITIATED: 'REFUND_INITIATED' as OrderPaymentStatusEnum,
        REFUNDSUCCESS: 'REFUND_SUCCESS' as OrderPaymentStatusEnum,
        PAYMENTNA: 'PAYMENT_NA' as OrderPaymentStatusEnum,
        PAYBYCASH: 'PAY_BY_CASH' as OrderPaymentStatusEnum,
        PAIDBYCASH: 'PAID_BY_CASH' as OrderPaymentStatusEnum
    };
    export type PaymentPreferenceEnum = 'PAY_BY_ONLINE' | 'PAY_BY_CASH';
    export const PaymentPreferenceEnum = {
        ONLINE: 'PAY_BY_ONLINE' as PaymentPreferenceEnum,
        CASH: 'PAY_BY_CASH' as PaymentPreferenceEnum
    };
}