/**
 * SmartdineNow API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CartItemModel } from './cartItemModel';
import { CartOfferInfoModel } from './cartOfferInfoModel';
import { CartPriceModel } from './cartPriceModel';
import { CartTaxInfoModel } from './cartTaxInfoModel';
import { RestaurantBranchModel } from './restaurantBranchModel';
import { RestaurantModel } from './restaurantModel';

export interface CartModel { 
    restaurantId?: string;
    restaurantBranchId?: string;
    tableId?: string;
    addressId?: string;
    restaurantModel?: RestaurantModel;
    restaurantBranchModel?: RestaurantBranchModel;
    cartId?: string;
    orderType?: CartModel.OrderTypeEnum;
    noOfItems?: string;
    cartPriceModel?: CartPriceModel;
    cartItemModels?: { [key: string]: CartItemModel; };
    cartTaxInfoModels?: { [key: string]: CartTaxInfoModel; };
    cartStatus?: CartModel.CartStatusEnum;
    cartOfferInfoModels?: { [key: string]: CartOfferInfoModel; };
    cartPriceModelsByItemType?: { [key: string]: CartPriceModel; };
}
export namespace CartModel {
    export type OrderTypeEnum = 'DINE_IN' | 'DELIVERY' | 'TAKEAWAY';
    export const OrderTypeEnum = {
        DINEIN: 'DINE_IN' as OrderTypeEnum,
        DELIVERY: 'DELIVERY' as OrderTypeEnum,
        TAKEAWAY: 'TAKEAWAY' as OrderTypeEnum
    };
    export type CartStatusEnum = 'DRAFT' | 'ORDERED' | 'ORDERING_INPROGRESS' | 'RETURNED' | 'NOT_ACCEPTED';
    export const CartStatusEnum = {
        DRAFT: 'DRAFT' as CartStatusEnum,
        ORDERED: 'ORDERED' as CartStatusEnum,
        ORDERINGINPROGRESS: 'ORDERING_INPROGRESS' as CartStatusEnum,
        RETURNED: 'RETURNED' as CartStatusEnum,
        NOTACCEPTED: 'NOT_ACCEPTED' as CartStatusEnum
    };
}