import { AfterViewInit, Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { QRCodeModel, RestaurantQrCodeRestControllerService, SmartDineResponseQRCodeModel } from 'src/app/swagger';
//import { Printer, PrintOptions } from '@awesome-cordova-plugins/printer/ngx';
import { Capacitor } from '@capacitor/core';
import { LoaderService } from '../../services/loader/loader.service';

type Platform = 'web' | 'android' | 'ios';

declare var window: any;

@Component({
  selector: 'app-qr-info',
  templateUrl: './qr-info.component.html',
  styleUrls: ['./qr-info.component.scss'],
})
export class QrInfoComponent implements OnInit {

  public static readonly id = 'qr-info-modal';

  @Input()
  public qrInfo: QRCodeModel | null = null;

  public printAvailability = false;

  constructor(
    private modalController: ModalController,
    //private printer: Printer,
    private loaderService: LoaderService,
    private elementRef: ElementRef,
  ) { }

  public onPrintClick() {
    const currentPlatform = Capacitor.getPlatform() as Platform;
    if (currentPlatform === 'web') {
      this.printWithWeb();
    } else {
      this.printWithDevice();
    }
  }

  public onCloseClick() {
    this.modalController.dismiss({}, '', QrInfoComponent.id);
  }

  public ngOnInit() {
    this.setPrintAvailability();
  }

  private setPrintAvailability() {
    this.printAvailability = false;
    const currentPlatform = Capacitor.getPlatform() as Platform;
    if (currentPlatform === 'web') {
      this.printAvailability = true;
      return;
    }

    // TODO: For now, enabling the print functionality for web.
    // this.printer.isAvailable().then(
    //   (availability: boolean) => {
    //     this.printAvailability = availability;
    //   },
    //   (err: any) => {
    //     console.error('Print support unavailable! :(', err);
    //   }
    // );
  }

  private printWithDevice() {
    this.loaderService.showLoader();
    // const printOptions: PrintOptions = {
    //   name: this.getFileName(),
    //   duplex: true,
    //   orientation: 'portrait',
    //   monochrome: true
    // };

    const content = this.elementRef.nativeElement as HTMLElement;
    // this.printer.print(content, printOptions).then((res) => {
    //   this.loaderService.hideLoader();
    //   console.log('Print Success!', res);
    // }, (err) => {
    //   this.loaderService.hideLoader();
    //   console.error('Print support unavailable! :(', err);
    // });
  }

  private printWithWeb() {
    window.print();
  }

  private getFileName() {
    const qrCodeInfo = this.qrInfo.qrCodeInfo;
    const restaurantCode = qrCodeInfo.rc;
    const branchCode = qrCodeInfo.rbc;
    let tableCode = qrCodeInfo.rbtc;
    return restaurantCode + '-' + branchCode + '-' + tableCode;
  }
}
