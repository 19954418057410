import { MenuItem } from "src/app/common/services/side-menu/side-menu.service";

export const  sideMenuItems: MenuItem[] = [
    {
      id: '',
      iconName: 'home-outline',
      name: 'Home',
      link: '/customer/home',
      pages: ['HomePage', 'MenusPage', 'MenuDetailsPage', 'CatalogPage'],
      enabled: true,
      functionality_name: null
    },
    {
      id: '',
      iconName: 'grid-outline',
      name: 'Orders',
      link: '/customer/orders',
      pages: ['OrdersPage', 'OrderPage', 'PaymentPage'],
      enabled: true,
      functionality_name: null
    },
    {
      id: '',
      iconName: 'person-outline',
      name: 'Profile',
      link: '/customer/profile',
      pages: ['ProfilePage'],
      enabled: true,
      functionality_name: null
    },

];