/**
 * SmartdineNow API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface OrderStatusModel { 
    restaurantId?: string;
    orderId?: string;
    restaurantBranchId?: string;
    currentStatus?: OrderStatusModel.CurrentStatusEnum;
    nextPossibleOrderStatuses?: Array<OrderStatusModel.NextPossibleOrderStatusesEnum>;
}
export namespace OrderStatusModel {
    export type CurrentStatusEnum = 'DRAFT' | 'ITEMS_RESERVED' | 'SUBMITTED' | 'CANCELLED' | 'DECLINED' | 'ACCEPTED' | 'PREPARING' | 'PREPARED' | 'DELIVERED' | 'NOT_ACCEPTED' | 'PICKED_UP';
    export const CurrentStatusEnum = {
        DRAFT: 'DRAFT' as CurrentStatusEnum,
        ITEMSRESERVED: 'ITEMS_RESERVED' as CurrentStatusEnum,
        SUBMITTED: 'SUBMITTED' as CurrentStatusEnum,
        CANCELLED: 'CANCELLED' as CurrentStatusEnum,
        DECLINED: 'DECLINED' as CurrentStatusEnum,
        ACCEPTED: 'ACCEPTED' as CurrentStatusEnum,
        PREPARING: 'PREPARING' as CurrentStatusEnum,
        PREPARED: 'PREPARED' as CurrentStatusEnum,
        DELIVERED: 'DELIVERED' as CurrentStatusEnum,
        NOTACCEPTED: 'NOT_ACCEPTED' as CurrentStatusEnum,
        PICKEDUP: 'PICKED_UP' as CurrentStatusEnum
    };
    export type NextPossibleOrderStatusesEnum = 'DRAFT' | 'ITEMS_RESERVED' | 'SUBMITTED' | 'CANCELLED' | 'DECLINED' | 'ACCEPTED' | 'PREPARING' | 'PREPARED' | 'DELIVERED' | 'NOT_ACCEPTED' | 'PICKED_UP';
    export const NextPossibleOrderStatusesEnum = {
        DRAFT: 'DRAFT' as NextPossibleOrderStatusesEnum,
        ITEMSRESERVED: 'ITEMS_RESERVED' as NextPossibleOrderStatusesEnum,
        SUBMITTED: 'SUBMITTED' as NextPossibleOrderStatusesEnum,
        CANCELLED: 'CANCELLED' as NextPossibleOrderStatusesEnum,
        DECLINED: 'DECLINED' as NextPossibleOrderStatusesEnum,
        ACCEPTED: 'ACCEPTED' as NextPossibleOrderStatusesEnum,
        PREPARING: 'PREPARING' as NextPossibleOrderStatusesEnum,
        PREPARED: 'PREPARED' as NextPossibleOrderStatusesEnum,
        DELIVERED: 'DELIVERED' as NextPossibleOrderStatusesEnum,
        NOTACCEPTED: 'NOT_ACCEPTED' as NextPossibleOrderStatusesEnum,
        PICKEDUP: 'PICKED_UP' as NextPossibleOrderStatusesEnum
    };
}