import { Component, Input, OnInit } from '@angular/core';

export type OrderTypes = 'DINE_IN' | 'DELIVERY' | 'TAKEAWAY';

export interface OrderTypeInfo {
  id: string;
  classId: string;
  label: string;
  color: string;
}

export const orderTypeInfo: { [key in OrderTypes]?: OrderTypeInfo; } = {
  DINE_IN: {
    id: 'DINE_IN',
    classId: 'order-type-dine_in',
    color: '#5598ff', // blue,
    label: 'Dine In',
  },
  DELIVERY: {
    id: 'DELIVERY',
    classId: 'order-type-delivery',
    color: '#498f7a', // green,
    label: 'Delivery',
  },
  TAKEAWAY: {
    id: 'TAKEAWAY',
    classId: 'order-type-takeaway',
    color: '#e0ac08', // yellow,
    label: 'Takeaway',
  },
};

@Component({
  selector: 'app-order-type',
  templateUrl: './order-type.component.html',
  styleUrls: ['./order-type.component.scss'],
})
export class OrderTypeComponent implements OnInit {

  public typeInfo: OrderTypeInfo | null = null;

  @Input()
  set orderType(value: OrderTypes) {
    this.typeInfo = orderTypeInfo[value];
  }

  constructor() { }

  ngOnInit() {}

}
