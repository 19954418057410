import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { UserProfilePage } from './core/pages/user-profile/user-profile.page';
import { HomeComponent } from './core/components/home/home.component';
import { Role } from './common/enums/role.enum';
import { RoleGuard } from './core/guards/role/role.guard';
import { LoginPage } from './core/pages/login/login.page';
import { SignUpPage } from './core/pages/sign-up/sign-up.page';
import { PasswordRecoveryPage } from './core/pages/password-recovery/password-recovery.page';
import { PrivacyPage } from './core/pages/privacy/privacy.page';
import { HomePage } from './core/pages/home/home.page';
import { UserSettingsPage } from './core/pages/user-settings/user-settings.page';


/**
 * Public routes which do not need session/login.
 */
export const publicRoutes: Routes = [
  {
    path: 'login',
    component: LoginPage
  },
  {
    path: 'sign-up',
    component: SignUpPage
  },
  {
    path: 'password-recovery',
    component: PasswordRecoveryPage,
  },
  {
    path: 'privacy',
    component: PrivacyPage,
  },
  {
    path: 'home',
    component: HomePage
  }
];

const routes: Routes = [
  {
    path: '',
    component:HomePage
  },
  {    
    path: 'customer',    
    component: HomeComponent,
    loadChildren: () => import('./customer/customer.module').then(m => m.CustomerModule)
  },
  {
    path: 'admin',
    component: HomeComponent,
    data: { expectedRole: Role.ADMINS },
    canActivate: [RoleGuard],
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminPageModule)
  },
  {
    path: 'resto-admin',    
    component: HomeComponent,
    data: { expectedRole: Role.RESTO_ADMINS },
    canActivate: [RoleGuard],
    loadChildren: () => import('./resto-admin/resto-admin.module').then(m => m.RestoAdminModule)
  },      
  // {
  //   path: 'user-profile',
  //   component: UserProfilePage,
  // },
  {
    path: 'user-settings',
    component: UserSettingsPage,
  },
  ...publicRoutes,

];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
