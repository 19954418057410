import { CartItemModel, CurrencyModel, MenuItemMapEntity, MenuItemMapModel, MenuItemMapPriceEntity, MenuItemMapStockEntity } from "src/app/swagger";
import { MenuItem } from "../menu-item/menu-item.model";

export interface PriceDetails extends MenuItemMapPriceEntity {
  message?: string;
}

export interface MenuItemMapping {
  menuItemMapId?: string;
  restaurantId?: string;
  restaurantBranchId?: string;
  menuId?: string;
  itemId?: string;
  
  menuItem: MenuItem;
  priceDetails?: PriceDetails | null;
  stockDetails?: MenuItemMapStockEntity | null;
  cartItem?: CartItemModel | null;
  currencyModel?: CurrencyModel | null;
}

export class MenuItemMapping implements MenuItemMapping {

  /**
   * Creates MenuItemMapping from MenuItemMapModel
   * @param obj MenuItemMapModel
   * @returns MenuItemMapping Instance.
   */
  public static create(obj: MenuItemMapModel) {

    const instance = new MenuItemMapping();
    instance.menuItemMapId = obj.menuItemMapId;
    instance.itemId = obj.itemId;
    instance.menuId = obj.menuId;
    instance.restaurantId = obj.restaurantId;
    instance.restaurantBranchId = obj.restaurantBranchId;
    
    instance.menuItem = obj.menuItemModel as MenuItem;
    instance.currencyModel = obj.currencyModel as CurrencyModel;
    instance.priceDetails = {
      menuItemMapPriceId: null,
      menuItemMapId: obj.menuItemMapId,

      restaurantId: obj.restaurantId,
      restaurantBranchId: obj.restaurantBranchId,

      price: obj.price,
      active: obj.availableForOrdering, // obj.orderable | obj.availableForOrdering
      fromDate: obj.orderableFromTime,
      toDate: null, // TODO: unaavaible in MenuItemMapModel
      message: obj.message,
    };

    return instance;
  }
}
