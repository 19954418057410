import { Component } from '@angular/core';
import { MenuController } from '@ionic/angular';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {

  public static readonly sideMenuId = 'side-menu'; // In case of change, do the same in the template.

  constructor(
    private menuController: MenuController
  ){

  }
  
  ngAfterViewInit() {
    this.menuController.enable(true, HomeComponent.sideMenuId);
  }

}
