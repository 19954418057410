import { Component, Input, OnInit } from '@angular/core';

export type OrderStatus = 'DRAFT' | 'ITEMS_RESERVED' | 'CANCELLED' | 'DECLINED' | 'ACCEPTED' | 'PREPARING' | 'PREPARED' | 'DELIVERED' | 'SUBMITTED' | 'REFUND_INITIATED' | 'REFUND_SUCCESS' | 'NOT_ACCEPTED' | 'PICKUP' | 'PICKED_UP' | 'PAYMENT_INITIATED' | 'PAYMENT_SUCCESS' | 'PAYMENT_REFUNDED' | 'PAYMENT_FAILED';

const statusProgress: { [key in OrderStatus]?: number; } = {
  ITEMS_RESERVED: 0,
  DRAFT: 0,
  PAYMENT_INITIATED: .4,
  PAYMENT_FAILED: .4,
  PAYMENT_REFUNDED: .4,
  REFUND_INITIATED: .4,
  REFUND_SUCCESS: .4,
  PAYMENT_SUCCESS: .5,
  SUBMITTED: .6,
  CANCELLED: .6,
  NOT_ACCEPTED: .6,
  DECLINED: .6,
  ACCEPTED: .7,
  PREPARING: .80,
  PREPARED: .85,
  PICKUP: .90,
  PICKED_UP: .90,
  DELIVERED: 1,
}

export interface OrderStatusInfo {
  id: string;
  classId: string;
  label: string;
  color: string;
  progress: number;
  allowFor?: string[];
}

export const statusInfo: { [key in OrderStatus]?: OrderStatusInfo; } = {
  ITEMS_RESERVED: {
    id: 'ITEMS_RESERVED',
    classId: 'items-reserved',
    label: 'Items Reserved',
    color: '#3880ff', // blue
    progress: 0,
  },
  DRAFT: {
    id: 'DRAFT',
    classId: 'draft',
    label: 'Draft',
    color: '#3880ff', // blue
    progress: 0,
  },
  PAYMENT_INITIATED: {
    id: 'PAYMENT_INITIATED',
    classId: 'payment-initiated',
    label: 'Payment Initiated',
    color: '#3880ff', // blue
    progress: .4,
  },
  PAYMENT_FAILED: {
    id: 'PAYMENT_FAILED',
    classId: 'payment-failed',
    label: 'Payment Failed',
    color: '#eb445a', // red
    progress: .4,
  },
  PAYMENT_REFUNDED: {
    id: 'PAYMENT_REFUNDED',
    classId: 'payment-refunded',
    label: 'Payment Refunded',
    color: '#ffc409', // yellow
    progress: .4,
  },
  REFUND_INITIATED: {
    id: 'REFUND_INITIATED',
    classId: 'refund-initiated',
    label: 'Refund Initiated',
    color: '#ffc409', // yellow
    progress: .4,
  },
  REFUND_SUCCESS: {
    id: 'REFUND_SUCCESS',
    classId: 'refund-success',
    label: 'Refund Success',
    color: '#ffc409', // yellow
    progress: .5,
  },
  PAYMENT_SUCCESS: {
    id: 'PAYMENT_SUCCESS',
    classId: 'payment-success',
    label: 'Payment Success',
    color: '#3880ff', // blue
    progress: .5,
  },
  SUBMITTED: {
    id: 'SUBMITTED',
    classId: 'submitted',
    label: 'Submitted',
    color: '#3880ff', // blue
    progress: .6,
  },
  CANCELLED: {
    id: 'CANCELLED',
    classId: 'cancelled',
    label: 'Cancelled',
    color: '#989aa2', // grey
    progress: .6,
  },
  NOT_ACCEPTED: {
    id: 'NOT_ACCEPTED',
    classId: 'not-accepted',
    label: 'Not Accepted',
    color: '#ffc409', // yellow
    progress: .6,
  },
  DECLINED: {
    id: 'DECLINED',
    classId: 'declined',
    label: 'Declined',
    color: '#eb445a', // red
    progress: .6,
  },
  ACCEPTED: {
    id: 'ACCEPTED',
    classId: 'accepted',
    label: 'Accepted',
    color: '#3880ff', // blue
    progress: .7,
    allowFor: ['kitchen'],
  },
  PREPARING: {
    id: 'PREPARING',
    classId: 'preparing',
    label: 'Preparing',
    color: '#3880ff', // blue
    progress: .8,
    allowFor: ['kitchen'],
  },
  PREPARED: {
    id: 'PREPARED',
    classId: 'prepared',
    label: 'Prepared',
    color: '#3880ff', // blue
    progress: .85,
    allowFor: ['kitchen'],
  },
  PICKUP: {
    id: 'PICKUP',
    classId: 'pickup',
    label: 'Pickup',
    color: '#3880ff', // blue
    progress: .9,
  },
  PICKED_UP: {
    id: 'PICKED_UP',
    classId: 'picked-up',
    label: 'Picked Up',
    color: '#3880ff', // blue
    progress: .9,
  },
  DELIVERED: {
    id: 'DELIVERED',
    classId: 'delivered',
    label: 'Delivered',
    color: '#2dd36f', // green
    progress: 1,
  },
}


@Component({
  selector: 'app-order-progress-bar',
  templateUrl: './order-progress-bar.component.html',
  styleUrls: ['./order-progress-bar.component.scss'],
})
export class OrderProgressBarComponent implements OnInit {


  public statusInfo: OrderStatusInfo | null = null;
  public _status: string;
  @Input()
  set status(value: OrderStatus) {
    this._status = value;
    this.statusInfo = statusInfo[value];
  }


  constructor() { }

  ngOnInit() {}

}
