import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-food-type-indicator',
  templateUrl: './food-type-indicator.component.html',
  styleUrls: ['./food-type-indicator.component.scss'],
})
export class FoodTypeIndicatorComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
