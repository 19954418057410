import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { MenuItem, SideMenuService } from '../../services/side-menu/side-menu.service';
import Auth from '@aws-amplify/auth';
import { MessageService } from '../../services/message/message.service';
import { HomeComponent } from 'src/app/core/components/home/home.component';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent implements OnInit {

  @Input()
  public menuItems: MenuItem[] = [];

  public selectedMenuItemIndex = -1;

  constructor(
    private sideMenuService: SideMenuService,
    private menuController: MenuController,
    private router: Router,
    private messageService: MessageService
  ) { }

  ngOnInit() {
    this.setMenuItems();
  }

  public onItemClick(_event: MouseEvent, _item: MenuItem, index: number) {
    this.sideMenuService.setSelectedMenuItemIndex(index);
    this.menuController.close(HomeComponent.sideMenuId);
  }

  private setMenuItems() {
    this.sideMenuService.getMenuItems().subscribe({
      next: (menuItems: MenuItem[]) => {
        this.menuItems = menuItems;
      }
    });

    this.sideMenuService.getSelectedMenuItemIndex().subscribe({
      next: (index: number) => {
        this.selectedMenuItemIndex = index;
      }
    })
  }

  public displayNotSubscribedMessage(feature: any) {
    this.messageService.showMessage("You have not subscribed to " + feature + ". Please contact Administrator");
  }

}
