/**
 * SmartdineNow API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { SmartDineImage } from './smartDineImage';

export interface MenuEntity { 
    creator?: string;
    modifier?: string;
    createdAt?: Date;
    modifiedAt?: Date;
    menuId?: string;
    name?: string;
    code?: string;
    description?: string;
    template?: boolean;
    restaurantId?: string;
    restaurantBranchId?: string;
    parentMenuId?: string;
    tags?: Array<string>;
    menuIconUrl?: string;
    menuIconImage?: SmartDineImage;
    menuImages?: Array<SmartDineImage>;
    noOfChildMenus?: number;
    orderableFromTime?: string;
    orderableToTime?: string;
    menuStatus?: MenuEntity.MenuStatusEnum;
}
export namespace MenuEntity {
    export type MenuStatusEnum = 'DRAFT' | 'PUBLISHED';
    export const MenuStatusEnum = {
        DRAFT: 'DRAFT' as MenuStatusEnum,
        PUBLISHED: 'PUBLISHED' as MenuStatusEnum
    };
}