import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Auth, CognitoUser } from '@aws-amplify/auth';
import { LoaderService } from '../../../common/services/loader/loader.service';
import { AuthService } from '../../services/auth/auth.service';
import { CognitoUserSession } from 'amazon-cognito-identity-js';

interface UserInfo {
  otpType: string;
  countryCode: string;
  username: string;
}

interface PasswordResetInfo {
  verificationCode: string;
  password: string;
  confirmPassword: string;
}

type DeliveryMedium = 'EMAIL';

interface ICodeDeliveryDetails {
  AttributeName: string;
  DeliveryMedium: DeliveryMedium;
  Destination: string;
}

interface IForgotPasswordResult {
  CodeDeliveryDetails: ICodeDeliveryDetails;
}

@Component({
  selector: 'app-password-recovery',
  templateUrl: './password-recovery.page.html',
  styleUrls: ['./password-recovery.page.scss'],
})
export class PasswordRecoveryPage implements OnInit {

  private session: CognitoUserSession | null = null;
  private username = '';

  public forgotPasswordform: FormGroup;
  public passwordResetform: FormGroup;

  public stage = 1;

  public codeDeliveryDetails: ICodeDeliveryDetails | null = null;

  public errorMessage: string = '';
  private timer: NodeJS.Timeout;
  public hidePassword:boolean = true;
  public hideCnfPassword:boolean = true;
  public iconName: string = 'call-outline';
  public otpResponseMessage: string|null = null;
  public passwordCriteria:any[] = [];
  public isPasswordTouched:boolean | false = false;

  constructor(
    private loaderService: LoaderService,
    private router: Router,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
  ) {
    activatedRoute.queryParams.subscribe((queryParams) => {
      this.username = queryParams?.username || '';
      if (this.username) {
        this.stage = 2;
      }
    });

    this.createForm();
    this.passwordCriteria = [
      { criterion: 'At least 8 characters long', icon: 'information-circle-outline', color: 'info' },
      { criterion: 'Include at least 1 uppercase letter (A-Z)', icon: 'information-circle-outline', color: 'info' },
      { criterion: 'Include at least 1 lowercase letter (a-z)', icon: 'information-circle-outline', color: 'info' },
      { criterion: 'Include at least 1 special character from (@, !, _, #, $)', icon: 'information-circle-outline', color: 'info' }
    ];
  }

  public ngOnInit() {
    this.setSession();
    this.forgotPasswordform.get('username').valueChanges.subscribe((value) => {
      this.adjustCountryCodeVisibility(value);
      this.getIconName();
    });
  }

  public createForm() {
    this.forgotPasswordform = new FormGroup({
      countryCode: new FormControl('+91', [Validators.required]),
      username: new FormControl('', [Validators.required])
    });

    this.passwordResetform = new FormGroup({
      verificationCode: new FormControl('', [Validators.required, Validators.pattern('[0-9]+'), Validators.minLength(6), Validators.maxLength(6)]),
      password: new FormControl('', [Validators.required]),
      confirmPassword: new FormControl('', [Validators.required]),
    })
  }

  private setSession() {
    this.session = null;
    this.authService.getSession().subscribe({
      next: (session: CognitoUserSession | null) => {
        if (session) {
          this.router.navigateByUrl('/', { replaceUrl: true });
          this.session = session;
        }
      }
    });
  }

  // for stage 1 verfication code sending form
  private isEmail(value: string): boolean {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(value);
  }

  private isMobile(value: string): boolean {
    const mobilePattern = /^[0-9]{10,15}$/;
    return mobilePattern.test(value);
  }

  public isCountryCodeHidden(): boolean {
    const username = this.forgotPasswordform.get('username')?.value;
    return /[a-zA-Z]/.test(username) || this.isEmail(username);
  }

  private getIconName() {
    const username = this.forgotPasswordform.get('username')?.value;
    this.iconName = this.isEmail(username) || /[a-zA-Z]/.test(username) ? 'mail-outline' : 'call-outline';
  }

  private adjustCountryCodeVisibility(value: string): void {
    if (/[a-zA-Z]/.test(value) || this.isEmail(value)) {
      this.forgotPasswordform.get('countryCode')?.clearValidators();
    } else {
      this.forgotPasswordform.get('countryCode')?.setValidators([Validators.required]);
    }
    this.forgotPasswordform.get('countryCode')?.updateValueAndValidity();
  }

  public onStep1FormSubmit() {
    this.forgotPasswordform.markAllAsTouched();
    if (!this.forgotPasswordform.valid) {
      this.setErrorMessage("Username can not be blank!");
      return;
    }
  
    const {countryCode, username } = this.forgotPasswordform.value;
    
    if (this.isEmail(username)) {
      if(!username.includes('@') || !username.includes('.')){
        this.setErrorMessage('Please enter a valid email address!');
        return;
      }else{
        this.sendVerificationCode(username);
      }
    }
    if (this.isMobile(username)) {
      if((!/^\d{10}$/.test(username))){
        this.setErrorMessage('Please enter a valid mobile number!');
        return;
      }else{
        this.sendVerificationCode(countryCode+""+username);
      }
    }
  }

  public async sendVerificationCode(username: string) {
    this.loaderService.showLoader();
    try {
      this.username = username;
      const res: IForgotPasswordResult = await Auth.forgotPassword(username);
      this.codeDeliveryDetails = res.CodeDeliveryDetails;
      console.info('Response:', res);
      this.otpResponseMessage = "An OTP has been sent to "+res.CodeDeliveryDetails.Destination+ " via "+res.CodeDeliveryDetails.DeliveryMedium;
      this.router.navigate([], { queryParams: { username: this.username }, relativeTo: this.activatedRoute, queryParamsHandling: 'merge' });
      this.stage = 2;

      this.forgotPasswordform.reset();
      this.loaderService.hideLoader();
    } catch (err) {
      this.forgotPasswordform.reset();
      this.loaderService.hideLoader();
      this.setErrorMessage('Unable to process your request. Try again later!');
      console.log('Unable to login!', err.message);
    }
  }

  //stage 2 password reset form
  private updatePasswordCriteria(password: string) {
    this.passwordCriteria.forEach((criterion) => {
      criterion.color = this.isPasswordTouched && this.validateCriterion(password, criterion.criterion) ? 'success' : 'info';
      criterion.icon = this.isPasswordTouched && this.validateCriterion(password, criterion.criterion) ? 'checkmark-circle-outline' : 'information-circle-outline';
    });
  }

  validateCriterion(password: string, criterion: string): boolean {
    if (!password) return false;
    switch (criterion) {
      case 'At least 8 characters long':
        return password.length >= 8;
      case 'Include at least 1 uppercase letter (A-Z)':
        return /[A-Z]/.test(password);
      case 'Include at least 1 lowercase letter (a-z)':
        return /[a-z]/.test(password);
      case 'Include at least 1 special character from (@, !, _, #, $)':
        return /[@!_#\$]/.test(password);
      default:
        return false;
    }
  }

  public onPasswordFieldFocus() {
    this.isPasswordTouched = true;
    this.updatePasswordCriteria(this.passwordResetform.get('password').value);
  }

  public isPasswordValidForSubmit(): boolean {
    const password = this.passwordResetform.get('password').value;
    return this.passwordCriteria.every((criterion) => this.validateCriterion(password, criterion.criterion));
  }

  public onStep2FormSubmit() {
    this.passwordResetform.markAllAsTouched();
    const passwordValid = this.isPasswordValidForSubmit();
    if (this.passwordResetform.valid && passwordValid) {
      this.updatePassword(this.passwordResetform.value);
    } else {
      this.setErrorMessage('Password does not meet all the criteria.');
    }
  }

  public async updatePassword(details: PasswordResetInfo) {
    this.loaderService.showLoader();
    try {
      await Auth.forgotPasswordSubmit(this.username, details.verificationCode, details.password); // no response available except status 200
      console.log(PasswordRecoveryPage.name, 'Account verified successfully!');
      this.router.navigate([], { queryParams: { username: null }, relativeTo: this.activatedRoute, queryParamsHandling: 'merge' });
      this.stage = 3;
      this.passwordResetform.reset();
      this.loaderService.hideLoader();
    } catch (err) {
      this.passwordResetform.reset();
      this.loaderService.hideLoader();

      let errorMessage = 'Unable to process your request. Try again later!';
      if (err.message) {
        errorMessage = err.message;
      }
      this.setErrorMessage(errorMessage);
      console.error(PasswordRecoveryPage.name, 'Unable to verify the email!', err);
    }
  }

  // common methods for both stages
  public setErrorMessage(message: string) {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.errorMessage = message;
    this.timer = setTimeout(() => {
      this.errorMessage = '';
    }, 5000);
  }

  public togglePasswordVisibility() {
    this.hidePassword = !this.hidePassword;
  }

  public toggleConfirmPassword() {
    this.hideCnfPassword = !this.hideCnfPassword;
  }

}
