import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Auth, CognitoUser } from '@aws-amplify/auth';
import { LoaderService } from '../../../common/services/loader/loader.service';
import { AuthService } from '../../services/auth/auth.service';
import { CognitoUserSession, ISignUpResult } from 'amazon-cognito-identity-js';
import { ModalController } from '@ionic/angular';
import { PrivacyPage } from '../privacy/privacy.page';

interface NewUserInfo {
  name: string;
  email: string;
  password: string;
  phoneNumber: string;
  countryCode: string;
}

interface VerificationInfo {
  verificationCode: string;
}

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.page.html',
  styleUrls: ['./sign-up.page.scss'],
})
export class SignUpPage implements OnInit {

  private session: CognitoUserSession | null = null;
  private username = '';

  public signUpform: FormGroup;
  public verficationform: FormGroup;

  public stage = 1;

  public signUpButtonDisabled: boolean = true;

  public errorMessage: string = '';
  private timer: NodeJS.Timeout;

  public isPrivacyModalOpen = false;

  public resendCodeDisabled = true;
  public isPasswordTouched: boolean = false;
  public passwordCriteria:any[] = [];
  public hidePassword: boolean = true;

  constructor(
    private loaderService: LoaderService,
    private router: Router,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private modalController: ModalController

  ) {
    activatedRoute.queryParams.subscribe((queryParams) => {
      this.username = queryParams?.username || '';
      if (this.username) {
        this.stage = 2;
      }
    });

    this.createForm();
    this.passwordCriteria = [
      { criterion: 'At least 8 characters long', icon: 'information-circle-outline', color: 'info' },
      { criterion: 'Include at least 1 uppercase letter (A-Z)', icon: 'information-circle-outline', color: 'info' },
      { criterion: 'Include at least 1 lowercase letter (a-z)', icon: 'information-circle-outline', color: 'info' },
      { criterion: 'Include at least 1 special character from (@, !, _, #, $)', icon: 'information-circle-outline', color: 'info' }
    ];
  }

  public ngOnInit() {
    this.signUpform.get('password')?.valueChanges.subscribe((password) => {
      this.updatePasswordCriteria(password);
    });    
    this.setSession();
  }

  public createForm() {
    this.signUpform = new FormGroup({
      name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.email]),
      password: new FormControl('', [Validators.required]),
      phoneNumber: new FormControl('', [Validators.required]),
      countryCode: new FormControl('+91', [Validators.required]),
      agreeToTC: new FormControl(false, [Validators.required]),
    });
    this.verficationform = new FormGroup({
      verificationCode: new FormControl('', [Validators.required, Validators.pattern('[0-9]+'), Validators.minLength(6), Validators.maxLength(6)]),
    })
  }

  public onStep1FormSubmit() {
    this.signUpform.markAllAsTouched();

    const passwordValid = this.isPasswordValidForSubmit();
    if (this.signUpform.valid && this.signUpform.get('agreeToTC').value === true && passwordValid) {
      this.signUp(this.signUpform.value);
    } else {
      if (!passwordValid) {
        this.setErrorMessage('Password does not meet all the criteria.');
      } else {
        this.setErrorMessage('Missing required input or agreement.');
      }
    }
  }

  public agreeToTCAction() {
    this.signUpButtonDisabled = this.signUpform.get('agreeToTC').value;
  }

  async showPrivacy() {
    const modal = await this.modalController.create({
      component: PrivacyPage,
      id: PrivacyPage.id
      });
      return await modal.present();
  }

  public onStep2FormSubmit() {
    this.verficationform.markAllAsTouched();
    if (this.verficationform.valid) {
      this.confirmSignUp(this.verficationform.value);
    }
  }

  private updatePasswordCriteria(password: string) {
    this.passwordCriteria.forEach((criterion) => {
      criterion.color = this.isPasswordTouched && this.validateCriterion(password, criterion.criterion) ? 'success' : 'info';
      criterion.icon = this.isPasswordTouched && this.validateCriterion(password, criterion.criterion) ? 'checkmark-circle-outline' : 'information-circle-outline';
    });
  }

  validateCriterion(password: string, criterion: string): boolean {
    if (!password) return false;
    switch (criterion) {
      case 'At least 8 characters long':
        return password.length >= 8;
      case 'Include at least 1 uppercase letter (A-Z)':
        return /[A-Z]/.test(password);
      case 'Include at least 1 lowercase letter (a-z)':
        return /[a-z]/.test(password);
      case 'Include at least 1 special character from (@, !, _, #, $)':
        return /[@!_#\$]/.test(password);
      default:
        return false;
    }
  }

  public onPasswordFieldFocus() {
    this.isPasswordTouched = true;
    this.updatePasswordCriteria(this.signUpform.get('password').value);
  }

  public isPasswordValidForSubmit(): boolean {
    const password = this.signUpform.get('password').value;
    return this.passwordCriteria.every((criterion) => this.validateCriterion(password, criterion.criterion));
  }

  private setSession() {
    this.session = null;
    this.authService.getSession().subscribe({
      next: (session: CognitoUserSession | null) => {
        if (session) {
          this.router.navigateByUrl('/', { replaceUrl: true });
          this.session = session;
        }
      }
    });
  }

  public async signUp(details: NewUserInfo) {
    this.loaderService.showLoader();
    try {
      const res: ISignUpResult = await Auth.signUp({
        username: details.countryCode + details.phoneNumber,
        password: details.password,
        attributes: {
          name: details.name,
          email: details.email,
          phone_number: details.countryCode + details.phoneNumber,
        },
      });
      console.log(SignUpPage.name, 'User account creation completed!', res);
      if (res.user) {
        this.username = res.user.getUsername();
        console.log(SignUpPage.name, 'Username', this.username);
        this.router.navigate([], { queryParams: { username: this.username }, relativeTo: this.activatedRoute, queryParamsHandling: 'merge' });
      }
      console.log(SignUpPage.name, 'User Account Verification -', res.userConfirmed);
      if (res.userConfirmed) {
        this.stage = 3;
      } else {
        this.stage = 2;
        this.setErrorMessage("Code is sent to " + res.codeDeliveryDetails.Destination + " via " + res.codeDeliveryDetails.DeliveryMedium);
        this.disableResendCodeButton();
      }
      this.signUpform.reset();
      this.loaderService.hideLoader();
    } catch (err) {
      //this.signUpform.reset();
      this.loaderService.hideLoader();
      let errorMessage = 'Unable to process your request. Try again later!';
      if (err.message) {
        errorMessage = err.message;
      }
      this.setErrorMessage(errorMessage);
      console.error(SignUpPage.name, 'Unable to regsiter!', err);
    }
  }

  public async confirmSignUp(details: VerificationInfo) {
    this.loaderService.showLoader();
    try {
      const res = await Auth.confirmSignUp(this.username, details.verificationCode);
      console.log(SignUpPage.name, 'Account verified successfully!', res);
      this.router.navigate([], { queryParams: { username: null }, relativeTo: this.activatedRoute, queryParamsHandling: 'merge' });
      this.stage = 3;
      this.verficationform.reset();
      this.loaderService.hideLoader();
    } catch (err) {
      this.verficationform.reset();
      this.loaderService.hideLoader();

      let errorMessage = 'Unable to process your request. Try again later!';
      if (err.message) {
        errorMessage = err.message;
      }
      this.setErrorMessage(errorMessage);
      console.error(SignUpPage.name, 'Unable to verify the account!', err);
    }
  }

  public setErrorMessage(message: string) {
    this.errorMessage = message;
  }

  public resendCodeAction() {
    this.setErrorMessage('');
    this.disableResendCodeButton();
    Auth.resendSignUp(this.username).then((response) => {
      console.log('resendSignUp response', response);           
      this.setErrorMessage("Code is sent to " + response.CodeDeliveryDetails.Destination + " via " + response.CodeDeliveryDetails.DeliveryMedium);
    });
  }

  public disableResendCodeButton() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.resendCodeDisabled = true; 
    this.timer = setTimeout(() => {
      this.resendCodeDisabled = false;
    }, 120000);
  }

  public togglePasswordVisibility() {
    this.hidePassword = !this.hidePassword;
  }
}
