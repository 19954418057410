import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';

export interface QuantityChangeEvent {
  value: number;
}

@Component({
  selector: 'app-quantity-selector',
  templateUrl: './quantity-selector.component.html',
  styleUrls: ['./quantity-selector.component.scss'],
})
export class QuantitySelectorComponent {

  public quantity = 0;

  @Input() lines: 'full' | 'none' = 'full';

  @Input()
  set value(value: number) {
    this.quantity = value || 0;
  }
  get value() {
    return this.quantity;
  }

  @Input()
  disabled = false;

  @Output()
  change = new EventEmitter<QuantityChangeEvent>();

  public onDecrease(_event: MouseEvent) {
    this.quantity--;
    this.change.emit({ value: this.quantity });
  }
  
  public onIncrease(_event: MouseEvent) {
    this.quantity++;
    this.change.emit({ value: this.quantity });
  }

}
