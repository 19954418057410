import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { UtilityService } from '../../services/utilities/utilities.service';
import { iRatingValue } from './star-rating/star-rating.component';

@Component({
  selector: 'app-feedback-form',
  templateUrl: './feedback-form.component.html',
  styleUrls: ['./feedback-form.component.scss'],
})
export class FeedbackFormComponent implements OnInit {
  public static readonly id = 'order-review-modal';

  @Input() public restaurantId: string = null;
  @Input() public restaurantBranchId: string = null;
  @Input() public orderId: string = null;

  public overviewValue: number = 0;
  public foodValue: number = 0;
  public serviceValue: number = 0;
  public ambienceValue: number = 0;

  public reviewForm: FormGroup = new FormGroup({
    customerName: new FormControl('', [Validators.required]),
    customerNumber: new FormControl('', [
      Validators.required,
      Validators.pattern('^[0-9]{10}$'),
    ]),
    comments: new FormControl('', []),
  });

  constructor(
    private modal: ModalController,
    private utilService: UtilityService
  ) {}

  ngOnInit() {
    console.log(this.restaurantId, this.restaurantBranchId, this.orderId);
  }

  public onRatingChange(value: iRatingValue) {
    if (value?.fieldName === 'ambience') {
      this.ambienceValue = value?.rating;
    } else if (value?.fieldName === 'food') {
      this.foodValue = value?.rating;
    } else if (value?.fieldName === 'service') {
      this.serviceValue = value?.rating;
    } else {
      this.overviewValue = value?.rating;
    }
  }

  public async onSave(): Promise<void> {
    if (this.reviewForm?.invalid) {
      this.utilService.showAlert(
        'Please fill all the required fields',
        'Invalid Input',
        'invalid'
      );
      return;
    }

    if (this.validateData() && this.reviewForm?.valid) {
      const payload = {
        customerName: this.reviewForm?.get('customerName')?.value,
        customerNumber: this.reviewForm?.get('customerNumber')?.value,
        overviewRating: this.overviewValue,
        foodRating: this.foodValue,
        serviceRating: this.serviceValue,
        ambienceRating: this.ambienceValue,
        restaurantId: this.restaurantId,
        restaurantBranchId: this.restaurantBranchId,
        orderId: this.orderId,
      };
    }
  }

  public validateData(): boolean {
    const formValue = this.reviewForm.value;

    if (formValue.customerName.trim() === '') {
      console.log('Customer name cannot be blank! Please enter the name.');
      return false;
    }

    if (!/^[0-9]{10}$/.test(formValue.customerNumber)) {
      console.log('Mobile number must be exactly 10 digits.');
      return false;
    }

    return true;
  }

  public onClose(): void {
    this.modal.dismiss('', '', FeedbackFormComponent.id);
  }
}
