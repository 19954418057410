// Pipe for Capitalize first letter of each word and replace underscores with spaces

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalizeReplace'
})
export class CapitalizeReplacePipe implements PipeTransform {

  transform(value: string): string {
    return value.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
  }

}
