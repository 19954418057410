import { Component,Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent implements OnInit {

  
  @Input() placeholder: string = 'Search...';
  @Output() searchChange = new EventEmitter<string>();
  searchText: string = '';
  timerID = null;
  constructor() { }

  ngOnInit() {}

  onSearchChange(event: any): void {
    let that = this;
    // that.searchChange.emit(that.searchText);
    clearTimeout(that.timerID);
    that.timerID = setTimeout(function(){
      that.searchChange.emit(that.searchText);
    }, 300)
  }

}
  