import { Component, OnInit } from '@angular/core';
import { Role } from 'src/app/common/enums/role.enum';
import { QrScannerService } from 'src/app/common/services/qr-scanner/qr-scanner.service';
import { UserProfileService } from 'src/app/common/services/user-profile/user-profile.service';
import { ProfileModel } from 'src/app/swagger';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent implements OnInit {

  public userProfile: ProfileModel;
  public isAdmin = false;
  public isRestoAdmin = false;
  public isCustomer = false;

  constructor(
    private userProfileService: UserProfileService,
    private qrScannerService: QrScannerService,
  ) { }

  public ngOnInit() {
    this.init();
  }

  public onScanClick() {
    this.qrScannerService.notify({ action: 'open-scanner' });
  }

  public init() {
    this.setUserProfile();
  }

  private setUserProfile() {
    this.userProfileService.getUserProfile().subscribe({
      next: (userProfile: ProfileModel) => {
        if(userProfile){
          this.userProfile = userProfile;
          this.isRestoAdmin = userProfile.roles.includes(Role.RESTO_ADMINS);
          this.isCustomer = userProfile.roles.includes(Role.USERS);
        }
      },
      error:(err) => {
        console.log('Unable to Get UserProfile ',err);
      }
    });
  }

}
