import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ProfileModel } from 'src/app/swagger';

export interface BadgeInfo {
  count: number;
  color?: 'primary' | 'secondary' | 'tertiary' | 'success' | 'warning' | 'danger' | 'dark' | 'medium' | 'light'; 
}

export interface MenuItem {
  id: string;
  iconName: string;
  iconSource?: 'ionic' | 'bootstrap';
  name: string;
  link: string;
  pages: string[];
  enabled: boolean;
  functionality_name: ProfileModel.AllowedFunctionalitiesByRestaurantIdEnum;
  badge?: BadgeInfo;
}

@Injectable({
  providedIn: 'root'
})
export class SideMenuService {

  private subject: BehaviorSubject<MenuItem[]> = new BehaviorSubject([]);
  private selectedMenuItemIndexSubject: BehaviorSubject<number> = new BehaviorSubject(-1);
  private selectedPageName: string;

  constructor() { }

  public setMenuItems(navMenu: MenuItem[]) {
    this.subject.next(navMenu);
    this.setSelectedMenuItemIndexByPageName();
  }

  public getMenuItems(): Observable<MenuItem[]> {
    return this.subject.asObservable();
  }

  public setSelectedMenuItemIndex(index: number) {
    this.selectedMenuItemIndexSubject.next(index);
  }

  public setSelectedMenuItemIndexByPageName(pageName?: string) {
    if (pageName) { this.selectedPageName = pageName; }
    let menuItems = this.subject.getValue();
    if(menuItems)
      menuItems.forEach((item, index) => {
        if (item.pages.includes(this.selectedPageName)) {
          this.setSelectedMenuItemIndex(index);
        }
      });
  }

  public getSelectedMenuItemIndex() {
    return this.selectedMenuItemIndexSubject.asObservable();
  }
}
