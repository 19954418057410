/**
 * SmartdineNow API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { GeoLocation } from './geoLocation';

export interface AddressEntity { 
    addressId?: string;
    addressName?: string;
    userId?: string;
    houseNumber?: string;
    buildingName?: string;
    address?: string;
    city?: string;
    district?: string;
    landmark?: string;
    state?: string;
    country?: string;
    countryCode?: string;
    pinCode?: string;
    location?: GeoLocation;
    addressType?: AddressEntity.AddressTypeEnum;
    addressTypeClassification?: AddressEntity.AddressTypeClassificationEnum;
}
export namespace AddressEntity {
    export type AddressTypeEnum = 'HOME' | 'OFFICE';
    export const AddressTypeEnum = {
        HOME: 'HOME' as AddressTypeEnum,
        OFFICE: 'OFFICE' as AddressTypeEnum
    };
    export type AddressTypeClassificationEnum = 'BILLING' | 'SHIPPING';
    export const AddressTypeClassificationEnum = {
        BILLING: 'BILLING' as AddressTypeClassificationEnum,
        SHIPPING: 'SHIPPING' as AddressTypeClassificationEnum
    };
}