/**
 * SmartdineNow API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface TaxRuleEntity { 
    taxRuleId?: string;
    restaurantId?: string;
    restaurantBranchId?: string;
    taxRuleCode?: string;
    taxRuleName?: string;
    taxRuleGroup?: string;
    taxRuleLevel?: TaxRuleEntity.TaxRuleLevelEnum;
    applicableItemTypes?: Array<TaxRuleEntity.ApplicableItemTypesEnum>;
    applicableOrderTypes?: Array<TaxRuleEntity.ApplicableOrderTypesEnum>;
    taxPerc?: number;
    flatTaxRate?: number;
}
export namespace TaxRuleEntity {
    export type TaxRuleLevelEnum = 'ITEM_LEVEL' | 'CART_LEVEL';
    export const TaxRuleLevelEnum = {
        ITEMLEVEL: 'ITEM_LEVEL' as TaxRuleLevelEnum,
        CARTLEVEL: 'CART_LEVEL' as TaxRuleLevelEnum
    };
    export type ApplicableItemTypesEnum = 'FOOD' | 'LIQUOR' | 'FOOD_5' | 'FOOD_12' | 'FOOD_18';
    export const ApplicableItemTypesEnum = {
        FOOD: 'FOOD' as ApplicableItemTypesEnum,
        LIQUOR: 'LIQUOR' as ApplicableItemTypesEnum,
        FOOD5: 'FOOD_5' as ApplicableItemTypesEnum,
        FOOD12: 'FOOD_12' as ApplicableItemTypesEnum,
        FOOD18: 'FOOD_18' as ApplicableItemTypesEnum
    };
    export type ApplicableOrderTypesEnum = 'DINE_IN' | 'DELIVERY' | 'TAKEAWAY';
    export const ApplicableOrderTypesEnum = {
        DINEIN: 'DINE_IN' as ApplicableOrderTypesEnum,
        DELIVERY: 'DELIVERY' as ApplicableOrderTypesEnum,
        TAKEAWAY: 'TAKEAWAY' as ApplicableOrderTypesEnum
    };
}