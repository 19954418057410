import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import {
  MenuEntity,
  MenuItemEntity,
  MenuRestControllerService,
  SmartDineResponseMenuItemEntity,
} from 'src/app/swagger';

@Component({
  selector: 'app-menu-selection',
  templateUrl: './menu-selection.component.html',
  styleUrls: ['./menu-selection.component.scss'],
})
export class MenuSelectionComponent implements OnInit {

  public static readonly id = 'menu-selection-modal';

  public menus: MenuEntity[];

  @Input()
  restaurantId: string | null = null;

  @Input()
  restaurantBranchId: string | null = null;

  constructor(
    private modalController: ModalController,
    private menuRestControllerService: MenuRestControllerService,
  ) { }

  public ngOnInit() {
    this.init();
  }

  public onCloseClick() {
    this.modalController.dismiss(null, '', MenuSelectionComponent.id);
  }

  public onMenuClick(_: MouseEvent, menu: MenuItemEntity, index: number) {
    this.modalController.dismiss({ selectedMenu: menu }, '', MenuSelectionComponent.id);
  }

  private init() {
    this.setMenus();
  }

  private setMenus() {
    this.menus = [];

    /*
     * The menu templates created by admin are available
     * to restaurant admin for menu creation.
     * The selected menu template will be cloned for the specific
     * restaurant or branch.
     * Restaurant ID and Branch ID should be null for retrieving menu templates
     * created by admin.
     */
    const restaurantId = this.restaurantBranchId ? this.restaurantId : null;
    const restaurantBranchId = null;
    this.menuRestControllerService.getMenus(restaurantId, restaurantBranchId).subscribe({
      next: (res: SmartDineResponseMenuItemEntity) => {
        this.menus = res.items;
      },
      error: (err) => {
        console.error('Unable to get menu templates!', err);
      }
    });
  }

}
