import { OrderProgressBarComponent } from './components/order-status/order-progress-bar.component';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';
import { CommonModule as NgCommonModule } from '@angular/common';

import { BaseComponent } from './components/base/base.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { SideMenuComponent } from './components/side-menu/side-menu.component';
import { FoodTypeIndicatorComponent } from './components/food-type-indicator/food-type-indicator.component';
import { MenusComponent } from './components/menus/menus.component';
import { MenuNavBarComponent } from './components/menu-nav-bar/menu-nav-bar.component';
import { MenuItemsComponent } from './components/menu-items/menu-items.component';
import { MenuItemMappingsComponent } from './components/menu-item-mappings/menu-item-mappings.component';
import { ItemSelectionComponent } from './components/item-selection/item-selection.component';
import { MenuUpsertionComponent } from './components/menu-upsertion/menu-upsertion.component';
import { ItemUpsertionComponent } from './components/item-upsertion/item-upsertion.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RestoBranchUpsertionComponent } from './components/resto-branch-upsertion/resto-branch-upsertion.component';
import { MenuSelectionComponent } from './components/menu-selection/menu-selection.component';
import { ItemPriceSelecionComponent } from '../common/components/item-price-selecion/item-price-selecion.component';
import { CalendarModule } from 'ion2-calendar';
import { QrScannerComponent } from './components/qr-scanner/qr-scanner.component';
import { QrInfoComponent } from './components/qr-info/qr-info.component';
import { TablesComponent } from './components/tables/tables.component';
import { DndDirective } from './directives/dnd.directive';
import { QuantitySelectorComponent } from './components/quantity-selector/quantity-selector.component';
import { ZXingScannerModule } from '@zxing/ngx-scanner'
import { ButtonGroupComponent } from './components/button-group/button-group.component';
import { CatalogComponent } from './components/catalog/catalog.component';
import { AppearDirective } from './directives/appear.directive';
import { RestaurantUpsertionComponent } from './components/restaurant-upsertion/restaurant-upsertion.component';
import { LogoComponent } from './components/logo/logo.component';
import { SignatureComponent } from './components/signature/signature.component';
import { MessagesComponent } from './components/messages/messages.component';
import { ShareoptionsComponent } from './components/shareoptions/shareoptions.component';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { GoogleMapsModule } from '@angular/google-maps';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { createTranslateLoader } from '../app.module';
import { HttpClient } from '@angular/common/http';
import { AddressComponent } from './components/maps/address/address.component';
import { SideBarComponent } from './components/side-bar/side-bar.component';
import { OrderTypeComponent } from './components/order-type/order-type.component';
import { FiltersComponent } from './components/filters/filters.component';
import { CapitalizeReplacePipe } from './pipes/capitalize-replace.pipe';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { RestaurantModalComponent } from './components/custom-restaurant-select/restaurant-modal.component';
import { NotificationComponent } from './components/notification/notification.component';
import { HelpSupportComponent } from './components/help-support/help-support.component';
import { ProfilePopoverComponent } from './components/profile-popover/profile-popover.component';
import { FeedbackFormComponent } from './components/feedback/feedback-form.component';
import { StarRatingComponent } from './components/feedback/star-rating/star-rating.component';
import { RemoveUnderScoreAndCaptitalizePipe } from './pipes/remove-under-score-and-captitalize.pipe';
import { CatalogSideMenusComponent } from './components/catalog/catalog-side-menus/catalog-side-menus.component';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    DndDirective,
    AppearDirective,

    // Common Components..
    BaseComponent,
    HeaderComponent,
    FooterComponent,
    SideMenuComponent,
    SideBarComponent,
    FoodTypeIndicatorComponent,
    CatalogComponent,
    CatalogSideMenusComponent,
    MenusComponent,
    MenuItemsComponent,
    MenuItemMappingsComponent,
    MenuUpsertionComponent,
    ItemUpsertionComponent,
    ItemSelectionComponent,
    MenuNavBarComponent,
    RestoBranchUpsertionComponent,
    RestaurantUpsertionComponent,
    MenuSelectionComponent,
    ItemPriceSelecionComponent,
    
    QrInfoComponent,
    TablesComponent,
    OrderProgressBarComponent,
    QuantitySelectorComponent,
    ButtonGroupComponent,
    LogoComponent,
    SignatureComponent,
    OrderTypeComponent,
    FiltersComponent,
    NotificationComponent,
    HelpSupportComponent,
    ProfilePopoverComponent,

    // Common Pages..
    MessagesComponent,
    ShareoptionsComponent,
    AddressComponent,
    QrScannerComponent,
    CapitalizeReplacePipe,
    SearchBarComponent,
    RestaurantModalComponent,
    StarRatingComponent,
    FeedbackFormComponent,
    RemoveUnderScoreAndCaptitalizePipe
  ],
  imports: [
    RouterModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    NgCommonModule,
    IonicStorageModule.forRoot(),
    CalendarModule,
    ZXingScannerModule,
    GoogleMapsModule,
    TranslateModule,
  ],
  exports: [
    DndDirective,
    AppearDirective,

    // Common Components..
    BaseComponent,
    HeaderComponent,
    FooterComponent,
    SideMenuComponent,
    SideBarComponent,
    FoodTypeIndicatorComponent,
    CatalogComponent,
    MenusComponent,
    MenuItemsComponent,
    MenuItemMappingsComponent,
    MenuUpsertionComponent,
    ItemUpsertionComponent,
    ItemSelectionComponent,
    MenuNavBarComponent,
    RestoBranchUpsertionComponent,
    MenuSelectionComponent,
    ItemPriceSelecionComponent,
    QrInfoComponent,
    TablesComponent,
    OrderProgressBarComponent,
    QuantitySelectorComponent,
    ButtonGroupComponent,
    OrderTypeComponent,
    FiltersComponent,
    NotificationComponent,
    HelpSupportComponent,
    ProfilePopoverComponent,

    // Common Pages..
    MessagesComponent,
    ShareoptionsComponent,
    SearchBarComponent,
    CapitalizeReplacePipe,
    RemoveUnderScoreAndCaptitalizePipe,
    CatalogSideMenusComponent
  ]
})
export class CommonModule { }
