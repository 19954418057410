/**
 * SmartdineNow API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { GeoLocation } from './geoLocation';

export interface RestaurantBranchModel { 
    restaurantBranchId?: string;
    restaurantBranchCode?: string;
    name?: string;
    description?: string;
    address?: string;
    city?: string;
    state?: string;
    country?: string;
    countryCode?: string;
    location?: GeoLocation;
    restaurantId?: string;
    gstIN?: string;
    fssai?: string;
    emailId?: string;
    website?: string;
    contactNumber?: string;
    pincode?: string;
    allowedOrderTypes?: Array<RestaurantBranchModel.AllowedOrderTypesEnum>;
    allowedPaymentPreferences?: Array<RestaurantBranchModel.AllowedPaymentPreferencesEnum>;
}
export namespace RestaurantBranchModel {
    export type AllowedOrderTypesEnum = 'DINE_IN' | 'DELIVERY' | 'TAKEAWAY';
    export const AllowedOrderTypesEnum = {
        DINEIN: 'DINE_IN' as AllowedOrderTypesEnum,
        DELIVERY: 'DELIVERY' as AllowedOrderTypesEnum,
        TAKEAWAY: 'TAKEAWAY' as AllowedOrderTypesEnum
    };
    export type AllowedPaymentPreferencesEnum = 'PAY_BY_ONLINE' | 'PAY_BY_CASH';
    export const AllowedPaymentPreferencesEnum = {
        ONLINE: 'PAY_BY_ONLINE' as AllowedPaymentPreferencesEnum,
        CASH: 'PAY_BY_CASH' as AllowedPaymentPreferencesEnum
    };
}