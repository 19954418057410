import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabsComponent } from '../core/components/tabs/tabs.component';
import { HomeComponent } from './components/home/home.component';
import { IonicModule } from '@ionic/angular';
import { CommonModule as SdCommonModule } from '../common/common.module';
import { LoginPage } from './pages/login/login.page';
import { SignUpPage } from './pages/sign-up/sign-up.page';
import { PasswordRecoveryPage } from './pages/password-recovery/password-recovery.page';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HomePage } from './pages/home/home.page';
import { PrivacyPage } from './pages/privacy/privacy.page';
import { SharedModule } from '../shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { UserProfilePage } from './pages/user-profile/user-profile.page';
import { UserSettingsPage } from './pages/user-settings/user-settings.page';

@NgModule({
  declarations: [
    LoginPage,
    SignUpPage,
    PasswordRecoveryPage,

    HomeComponent,
    HomePage,
    UserProfilePage,
    UserSettingsPage,
    PrivacyPage
  ],
  imports: [
    IonicModule,
    CommonModule,
    SdCommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    TranslateModule,
  ],
  exports: [
    LoginPage,
    SignUpPage,
    PasswordRecoveryPage,

    HomeComponent,
    HomePage,
    UserProfilePage,
    UserSettingsPage,
    PrivacyPage
  ]
})
export class CoreModule { }
