import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { MessageService } from 'src/app/common/services/message/message.service';
import { UserProfileService } from 'src/app/common/services/user-profile/user-profile.service';
import { ProfileModel } from 'src/app/swagger';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {

  constructor(
    private userProfileService: UserProfileService,
    private router: Router,
    private messageService: MessageService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean> {
    const expectedRole = route.data.expectedRole;
    const skipRoleCheck = _state;
  
    if (skipRoleCheck) {
      console.log("Permission Granted! Forwarding to Users Home page");
      return of(true);
    }
    
    if (this.userProfileService.getUserLoggedIn()) {
      return this.userProfileService.getUserProfile().pipe(
        map((userProfile: ProfileModel) => {
          const isAllowed = userProfile.roles.includes(expectedRole);
          if (!isAllowed) {
            this.router.navigateByUrl('/');
          }
          console.log("Permission Granted!");
          return isAllowed;
        })
      );
    } else {
      console.log('Permission Denied! Forwarding to Home Page');
      //this.messageService.showMessage('You are not logged In. Please login!')
      this.router.navigateByUrl('/');
    }   
  }

}
