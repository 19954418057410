/**
 * SmartdineNow API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { PaymentGatewayPaymentRefEntity } from './paymentGatewayPaymentRefEntity';
import { PaymentOptionModel } from './paymentOptionModel';

export interface PaymentTransactionValidationModel { 
    paymentTransactionId?: string;
    restaurantId?: string;
    restaurantBranchId?: string;
    userId?: string;
    parentOrderId?: string;
    orderIds?: Array<string>;
    paymentOptionModel?: PaymentOptionModel;
    paymentGatewayResponse?: string;
    paymentStatus?: PaymentTransactionValidationModel.PaymentStatusEnum;
    paymentGatewayOrderStatus?: string;
    paymentGatewayPaymentStatus?: string;
    paymentGatewayOrderId?: string;
    paymentGatewayPaymentId?: string;
    paymentGatewayPaymentAmount?: string;
    paymentGatewayPaymentCurrency?: string;
    paymentGatewayPaymentRefEntities?: Array<PaymentGatewayPaymentRefEntity>;
}
export namespace PaymentTransactionValidationModel {
    export type PaymentStatusEnum = 'INITIATED' | 'CREATED' | 'AUTHORIZED' | 'CAPTURED' | 'REFUNDED' | 'FAILED' | 'PAY_BY_CASH' | 'PAY_LATER';
    export const PaymentStatusEnum = {
        INITIATED: 'INITIATED' as PaymentStatusEnum,
        CREATED: 'CREATED' as PaymentStatusEnum,
        AUTHORIZED: 'AUTHORIZED' as PaymentStatusEnum,
        CAPTURED: 'CAPTURED' as PaymentStatusEnum,
        REFUNDED: 'REFUNDED' as PaymentStatusEnum,
        FAILED: 'FAILED' as PaymentStatusEnum,
        PAYBYCASH: 'PAY_BY_CASH' as PaymentStatusEnum,
        PAYLATER: 'PAY_LATER' as PaymentStatusEnum
    };
}