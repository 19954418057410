import { Injectable } from '@angular/core';
import { CurrencyModel } from '../model/currencyModel';

@Injectable({
  providedIn: 'root'
})
export class RestaurantCurrencyService {

  constructor() { }

  private prefCurrency: CurrencyModel;

  setPrefCurrency(currency: CurrencyModel) {
    this.prefCurrency = currency;
  }

  getPrefCurrency(): CurrencyModel {
    return this.prefCurrency;
  }
}
