/**
 * SmartdineNow API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ItemQuantity } from './itemQuantity';
import { SmartDineImage } from './smartDineImage';

export interface MenuItemEntity { 
    itemId?: string;
    name?: string;
    description?: string;
    iconUrl?: string;
    categoryIds?: Array<string>;
    template?: boolean;
    vegetarian?: boolean;
    restaurantId?: string;
    restaurantBranchId?: string;
    tags?: Array<string>;
    iconImage?: SmartDineImage;
    itemType?: MenuItemEntity.ItemTypeEnum;
    itemQuantity?: ItemQuantity;
}
export namespace MenuItemEntity {
    export type ItemTypeEnum = 'FOOD' | 'LIQUOR' | 'FOOD_5' | 'FOOD_12' | 'FOOD_18';
    export const ItemTypeEnum = {
        FOOD: 'FOOD' as ItemTypeEnum,
        LIQUOR: 'LIQUOR' as ItemTypeEnum,
        FOOD5: 'FOOD_5' as ItemTypeEnum,
        FOOD12: 'FOOD_12' as ItemTypeEnum,
        FOOD18: 'FOOD_18' as ItemTypeEnum
    };
}