/**
 * SmartdineNow API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface CartTaxInfoModel { 
    taxRuleName?: string;
    taxRuleDesc?: string;
    taxRuleLevel?: CartTaxInfoModel.TaxRuleLevelEnum;
    taxPerc?: number;
    flatTaxRate?: number;
    taxAmount?: number;
    taxedAmount?: number;
    taxRuleCode?: string;
    taxRuleGroup?: string;
}
export namespace CartTaxInfoModel {
    export type TaxRuleLevelEnum = 'ITEM_LEVEL' | 'CART_LEVEL';
    export const TaxRuleLevelEnum = {
        ITEMLEVEL: 'ITEM_LEVEL' as TaxRuleLevelEnum,
        CARTLEVEL: 'CART_LEVEL' as TaxRuleLevelEnum
    };
}