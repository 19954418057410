import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

/* Add Amplify imports */
import { AmplifyUIAngularModule } from '@aws-amplify/ui-angular';
import Amplify from 'aws-amplify';
import awsconfig from '../aws-exports';
import { ApiModule, BASE_PATH } from './swagger';

import { HttpClientModule } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from './core/services/auth/auth.service';
import { CoreModule } from './core/core.module';
import { CommonModule as SdCommonModule } from './common/common.module';
import { Device } from '@ionic-native/device/ngx';
import { StorageService } from './common/services/storage/storage.service';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { GoogleMapsModule } from '@angular/google-maps';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { MapOfferModalComponent } from './resto-admin/components/map-offer-modal/map-offer-modal.component'


//import { Printer } from '@awesome-cordova-plugins/printer/ngx';

function storageFactory(storageService: StorageService) {
  return storageService.init.bind(storageService);
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

/* Configure Amplify resources */
Amplify.configure(awsconfig);

function authFactory(authService: AuthService): Promise<void> {
  return authService.init.bind(authService);
}

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    AmplifyUIAngularModule,   
    BrowserAnimationsModule,
    CommonModule,
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    CoreModule,
    HttpClientModule,
    ApiModule.forRoot(AuthService.getConfiguration),
    SdCommonModule,
    ZXingScannerModule,
    ReactiveFormsModule,
    FormsModule,
    GoogleMapsModule,
    NgxChartsModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    { provide: APP_INITIALIZER, useFactory: storageFactory, deps: [StorageService], multi: true },
    { provide: APP_INITIALIZER, useFactory: authFactory, deps: [AuthService], multi: true },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: BASE_PATH, useValue: environment.apiBaseUrl },
    Device,
   // Printer
  ],
  bootstrap: [AppComponent],
  exports: []
})
export class AppModule {}

