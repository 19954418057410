import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Geolocation, Position } from '@capacitor/geolocation';
import {MapInfoWindow, MapMarker} from '@angular/google-maps';
import { GeoLocation } from 'src/app/swagger';
import { ModalController } from '@ionic/angular';
@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
})
export class AddressComponent implements OnInit {

  
  public static readonly id = 'map-address-modal';

  @Input()
  public location: GeoLocation | null = null;

  @ViewChild(MapInfoWindow) infoWindow: MapInfoWindow;

  center: google.maps.LatLngLiteral = {lat: 24, lng: 12};
  zoom = 16;
  infoWindowContent = '';
  markers: any = [];

  addMarker(location: GeoLocation) {
    this.markers.push({
      position: {
        lat: location.latitude,
        lng: location.longitude,
      },
      label: {
        color: 'red',
        text: 'Marker label ' + (this.markers.length + 1),
      },
      
      title: 'Marker title ' + (this.markers.length + 1),
      options: { draggable: true },
    });
  }

  openInfoWindow(marker: MapMarker, content) { 
       
    const geocoder = new google.maps.Geocoder();

    this.markers[0].position = marker.getPosition().toJSON();;
    
    geocoder
    .geocode({ location: marker.getPosition()}, (geocoderResults) => {
      if (geocoderResults) {
        this.infoWindowContent = geocoderResults[0].formatted_address;
        this.infoWindow.open(marker);
      } else {
        window.alert("No results found");
      }
    });



  }

  constructor(private modalController: ModalController) { 
       
    
  }

  public async initMap() {
    if (this.location == null || this.location.latitude == null || this.location.longitude == null) {
      const coordinates = await Geolocation.getCurrentPosition();
      this.location =  {latitude: coordinates.coords.latitude, longitude:coordinates.coords.longitude};   
    }
    this.center = {lat: this.location.latitude, lng: this.location.longitude};
    this.addMarker(this.location);
  }

  

  ngOnInit() {
    this.initMap(); 
  }

  onSaveClick() {
    this.modalController.dismiss({location: this.markers[0].position},'', AddressComponent.id);
  }

  onCloseClick() {
    this.modalController.dismiss({}, '', AddressComponent.id);
  }
}
