/**
 * SmartdineNow API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CurrencyModel } from './currencyModel';
import { RestaurantModelPrefTimeZone } from './restaurantModelPrefTimeZone';
import { SmartDineImage } from './smartDineImage';

export interface RestaurantEntity { 
    creator?: string;
    modifier?: string;
    createdAt?: Date;
    modifiedAt?: Date;
    restaurantId?: string;
    restaurantCode?: string;
    name?: string;
    description?: string;
    iconUrl?: string;
    gstIN?: string;
    fssai?: string;
    iconImage?: SmartDineImage;
    restaurantImages?: Array<SmartDineImage>;
    restaurantStatus?: RestaurantEntity.RestaurantStatusEnum;
    validUpto?: Date;
    maxNoOfTables?: number;
    maxNoOfRestaurantBranches?: number;
    restaurantSubscriptionEntityId?: string;
    active?: boolean;
    prefCurrencyCode?: string;
    prefTimeZoneId?: string;
    prefTimeZone?: RestaurantModelPrefTimeZone;
    prefCurrency?: CurrencyModel;
}
export namespace RestaurantEntity {
    export type RestaurantStatusEnum = 'DRAFT' | 'PENDING_APPROVAL' | 'APPROVED' | 'REJECTED' | 'PUBLISHED' | 'UNPUBLISHED' | 'PAYMENT_PENDING';
    export const RestaurantStatusEnum = {
        DRAFT: 'DRAFT' as RestaurantStatusEnum,
        PENDINGAPPROVAL: 'PENDING_APPROVAL' as RestaurantStatusEnum,
        APPROVED: 'APPROVED' as RestaurantStatusEnum,
        REJECTED: 'REJECTED' as RestaurantStatusEnum,
        PUBLISHED: 'PUBLISHED' as RestaurantStatusEnum,
        UNPUBLISHED: 'UNPUBLISHED' as RestaurantStatusEnum,
        PAYMENTPENDING: 'PAYMENT_PENDING' as RestaurantStatusEnum
    };
}