import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, ReplaySubject } from 'rxjs';
import { ProfileModel, ProfileRestControllerForUsersService, SmartDineResponseProfileModel } from 'src/app/swagger';
import { AuthService } from '../../../core/services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {

  private subject: ReplaySubject<ProfileModel> = new ReplaySubject(1);
  private userLoggedIn: boolean = false;

  constructor(
    private router: Router,
    private profileRestControllerForUsersService: ProfileRestControllerForUsersService,
    private authService: AuthService,
  ) { }

  public getUserProfile(forceRefresh = false): Observable<ProfileModel> {
    if (forceRefresh) {
      this.loadUserProfile();
    }
    return this.subject.asObservable();
  }

  public setUserProfile(userProfile: ProfileModel) {
    this.subject.next(userProfile);
  }

  private loadUserProfile() {
    this.profileRestControllerForUsersService.getLoggedInUserProfile().subscribe({
      next: (res: SmartDineResponseProfileModel) => {
        this.setUserProfile(res.item);
        this.userLoggedIn = true;
      },
      error: (err: { message: string, ok: boolean, status: number }) => {
        if (err.status === 401) {
          //this.router.navigateByUrl('/login', { replaceUrl: true });
        } else if (err.status === 403) {
          this.authService.clearSession();
          //this.router.navigateByUrl('/home', { replaceUrl: true });
        }
        console.error('Unable to get current user profile!', err);
        console.error(err.message);
      }
    });
  }

  public getUserLoggedIn() {
    return this.userLoggedIn;
  }
}
