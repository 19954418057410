/**
 * SmartdineNow API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { SmartDineImage } from './smartDineImage';

export interface ProductModel { 
    productId?: string;
    name?: string;
    description?: string;
    images?: SmartDineImage;
    quantity?: number;
    qtyMeasurementUnit?: ProductModel.QtyMeasurementUnitEnum;
}
export namespace ProductModel {
    export type QtyMeasurementUnitEnum = 'MILLI_LITERS' | 'LITERS' | 'KILO_LITERS' | 'PINT' | 'GALLON' | 'MILLI_GRAMS' | 'GRAMS' | 'KILO_GRAMS' | 'TONNE' | 'SMALL' | 'LARGE' | 'HALF' | 'FULL';
    export const QtyMeasurementUnitEnum = {
        MILLILITERS: 'MILLI_LITERS' as QtyMeasurementUnitEnum,
        LITERS: 'LITERS' as QtyMeasurementUnitEnum,
        KILOLITERS: 'KILO_LITERS' as QtyMeasurementUnitEnum,
        PINT: 'PINT' as QtyMeasurementUnitEnum,
        GALLON: 'GALLON' as QtyMeasurementUnitEnum,
        MILLIGRAMS: 'MILLI_GRAMS' as QtyMeasurementUnitEnum,
        GRAMS: 'GRAMS' as QtyMeasurementUnitEnum,
        KILOGRAMS: 'KILO_GRAMS' as QtyMeasurementUnitEnum,
        TONNE: 'TONNE' as QtyMeasurementUnitEnum,
        SMALL: 'SMALL' as QtyMeasurementUnitEnum,
        LARGE: 'LARGE' as QtyMeasurementUnitEnum,
        HALF: 'HALF' as QtyMeasurementUnitEnum,
        FULL: 'FULL' as QtyMeasurementUnitEnum
    };
}