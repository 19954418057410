/**
 * SmartdineNow API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { SmartDineResponseRestaurantBranchModel } from '../model/smartDineResponseRestaurantBranchModel';
import { SmartDineResponseRestaurantBranchTableModel } from '../model/smartDineResponseRestaurantBranchTableModel';
import { SmartDineResponseRestaurantModel } from '../model/smartDineResponseRestaurantModel';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class RestaurantUsersRestControllerService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param restaurantId 
     * @param restaurantBranchId 
     * @param locale 
     * @param pageNo 
     * @param rows 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRestaurantBranchTables(restaurantId: string, restaurantBranchId: string, locale?: string, pageNo?: number, rows?: number, observe?: 'body', reportProgress?: boolean): Observable<SmartDineResponseRestaurantBranchTableModel>;
    public getRestaurantBranchTables(restaurantId: string, restaurantBranchId: string, locale?: string, pageNo?: number, rows?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SmartDineResponseRestaurantBranchTableModel>>;
    public getRestaurantBranchTables(restaurantId: string, restaurantBranchId: string, locale?: string, pageNo?: number, rows?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SmartDineResponseRestaurantBranchTableModel>>;
    public getRestaurantBranchTables(restaurantId: string, restaurantBranchId: string, locale?: string, pageNo?: number, rows?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (restaurantId === null || restaurantId === undefined) {
            throw new Error('Required parameter restaurantId was null or undefined when calling getRestaurantBranchTables.');
        }

        if (restaurantBranchId === null || restaurantBranchId === undefined) {
            throw new Error('Required parameter restaurantBranchId was null or undefined when calling getRestaurantBranchTables.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (locale !== undefined && locale !== null) {
            queryParameters = queryParameters.set('locale', <any>locale);
        }
        if (pageNo !== undefined && pageNo !== null) {
            queryParameters = queryParameters.set('pageNo', <any>pageNo);
        }
        if (rows !== undefined && rows !== null) {
            queryParameters = queryParameters.set('rows', <any>rows);
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<SmartDineResponseRestaurantBranchTableModel>('get',`${this.basePath}/api/users/restaurants/${encodeURIComponent(String(restaurantId))}/branches/${encodeURIComponent(String(restaurantBranchId))}/tables`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param restaurantId 
     * @param locale 
     * @param pageNo 
     * @param rows 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRestaurantBranches(restaurantId: string, locale?: string, pageNo?: number, rows?: number, observe?: 'body', reportProgress?: boolean): Observable<SmartDineResponseRestaurantBranchModel>;
    public getRestaurantBranches(restaurantId: string, locale?: string, pageNo?: number, rows?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SmartDineResponseRestaurantBranchModel>>;
    public getRestaurantBranches(restaurantId: string, locale?: string, pageNo?: number, rows?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SmartDineResponseRestaurantBranchModel>>;
    public getRestaurantBranches(restaurantId: string, locale?: string, pageNo?: number, rows?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (restaurantId === null || restaurantId === undefined) {
            throw new Error('Required parameter restaurantId was null or undefined when calling getRestaurantBranches.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (locale !== undefined && locale !== null) {
            queryParameters = queryParameters.set('locale', <any>locale);
        }
        if (pageNo !== undefined && pageNo !== null) {
            queryParameters = queryParameters.set('pageNo', <any>pageNo);
        }
        if (rows !== undefined && rows !== null) {
            queryParameters = queryParameters.set('rows', <any>rows);
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<SmartDineResponseRestaurantBranchModel>('get',`${this.basePath}/api/users/restaurants/${encodeURIComponent(String(restaurantId))}/branches`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param restaurantId 
     * @param locale 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRestaurantDetail(restaurantId: string, locale?: string, observe?: 'body', reportProgress?: boolean): Observable<SmartDineResponseRestaurantModel>;
    public getRestaurantDetail(restaurantId: string, locale?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SmartDineResponseRestaurantModel>>;
    public getRestaurantDetail(restaurantId: string, locale?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SmartDineResponseRestaurantModel>>;
    public getRestaurantDetail(restaurantId: string, locale?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (restaurantId === null || restaurantId === undefined) {
            throw new Error('Required parameter restaurantId was null or undefined when calling getRestaurantDetail.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (locale !== undefined && locale !== null) {
            queryParameters = queryParameters.set('locale', <any>locale);
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<SmartDineResponseRestaurantModel>('get',`${this.basePath}/api/users/restaurants/${encodeURIComponent(String(restaurantId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param keyword 
     * @param locale 
     * @param pageNo 
     * @param rows 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRestaurants(keyword: string, locale?: string, pageNo?: number, rows?: number, observe?: 'body', reportProgress?: boolean): Observable<SmartDineResponseRestaurantModel>;
    public getRestaurants(keyword: string, locale?: string, pageNo?: number, rows?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SmartDineResponseRestaurantModel>>;
    public getRestaurants(keyword: string, locale?: string, pageNo?: number, rows?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SmartDineResponseRestaurantModel>>;
    public getRestaurants(keyword: string, locale?: string, pageNo?: number, rows?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (keyword === null || keyword === undefined) {
            throw new Error('Required parameter keyword was null or undefined when calling getRestaurants.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (keyword !== undefined && keyword !== null) {
            queryParameters = queryParameters.set('keyword', <any>keyword);
        }
        if (locale !== undefined && locale !== null) {
            queryParameters = queryParameters.set('locale', <any>locale);
        }
        if (pageNo !== undefined && pageNo !== null) {
            queryParameters = queryParameters.set('pageNo', <any>pageNo);
        }
        if (rows !== undefined && rows !== null) {
            queryParameters = queryParameters.set('rows', <any>rows);
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<SmartDineResponseRestaurantModel>('get',`${this.basePath}/api/users/restaurants`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
