/**
 * SmartdineNow API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface InventoryMasterModel { 
    inventoryMasterId?: string;
    restaurantId?: string;
    restaurantBranchId?: string;
    productId?: string;
    categoryId?: string;
    availableQuantity?: number;
    prefMeasurementUnit?: InventoryMasterModel.PrefMeasurementUnitEnum;
}
export namespace InventoryMasterModel {
    export type PrefMeasurementUnitEnum = 'MILLI_LITERS' | 'LITERS' | 'KILO_LITERS' | 'PINT' | 'GALLON' | 'MILLI_GRAMS' | 'GRAMS' | 'KILO_GRAMS' | 'TONNE' | 'SMALL' | 'LARGE' | 'HALF' | 'FULL';
    export const PrefMeasurementUnitEnum = {
        MILLILITERS: 'MILLI_LITERS' as PrefMeasurementUnitEnum,
        LITERS: 'LITERS' as PrefMeasurementUnitEnum,
        KILOLITERS: 'KILO_LITERS' as PrefMeasurementUnitEnum,
        PINT: 'PINT' as PrefMeasurementUnitEnum,
        GALLON: 'GALLON' as PrefMeasurementUnitEnum,
        MILLIGRAMS: 'MILLI_GRAMS' as PrefMeasurementUnitEnum,
        GRAMS: 'GRAMS' as PrefMeasurementUnitEnum,
        KILOGRAMS: 'KILO_GRAMS' as PrefMeasurementUnitEnum,
        TONNE: 'TONNE' as PrefMeasurementUnitEnum,
        SMALL: 'SMALL' as PrefMeasurementUnitEnum,
        LARGE: 'LARGE' as PrefMeasurementUnitEnum,
        HALF: 'HALF' as PrefMeasurementUnitEnum,
        FULL: 'FULL' as PrefMeasurementUnitEnum
    };
}