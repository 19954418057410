import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export interface QrSNotification {
  action: 'open-scanner';
}

@Injectable({
  providedIn: 'root'
})
export class QrScannerService {

  private notificationSubject = new Subject<QrSNotification>();

  public getNotifications(): Observable<QrSNotification> {
    return this.notificationSubject.asObservable();
  }

  public notify(message: QrSNotification) {
    this.notificationSubject.next(message);
  }
}
