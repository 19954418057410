import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.page.html',
  styleUrls: ['./privacy.page.scss'],
})
export class PrivacyPage implements OnInit {

  public static readonly id = 'privacy-page-id';
  public isModalOpen: boolean = false;
  constructor(
    private modalController: ModalController) { }

  ngOnInit() {
  }

  public onCloseClick() {
    this.modalController.dismiss({}, '', PrivacyPage.id);
  }
}
