import { Component, OnInit } from '@angular/core';
import { ProfileModel } from '../../../swagger';
import { UserProfileService } from 'src/app/common/services/user-profile/user-profile.service';

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.page.html',
  styleUrls: ['./user-settings.page.scss'],
})
export class UserSettingsPage implements OnInit {

  public userProfile: ProfileModel | null = null;
  public userFullName = '';
  useBiometrics: boolean;
  usePasscode: boolean;
  useSecureStorageMode: boolean;
  biometricType: string;

  constructor(
    private userProfileService: UserProfileService,
  ) { }

  ngOnInit() {
    this.userProfile = null;
    this.userFullName = '';
    this.userProfileService.getUserProfile().subscribe({
      next: (userProfile: ProfileModel) => {
        this.userProfile = userProfile;
      }
    });
   }

   async authModeChanged() {
  }


}
