/**
 * SmartdineNow API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { BehaviorSubject, Observable }                                        from 'rxjs';

import { OrderModel } from '../model/orderModel';
import { SmartDineResponseOrderModel } from '../model/smartDineResponseOrderModel';
import { SmartDineResponseOrderSummaryModel } from '../model/smartDineResponseOrderSummaryModel';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class OrderRestControllerForUsersService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    private orderListUpdatedSource = new BehaviorSubject<boolean>(false);
    orderListUpdated$ = this.orderListUpdatedSource.asObservable();
    private orderIdSubject = new BehaviorSubject<string | null>(null);


    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    public notifyOrderListUpdated() {
        this.orderListUpdatedSource.next(true);
    }

    public setOrderId(orderId: string) {
        if (orderId === null || orderId === undefined) {
            throw new Error('Required parameter orderId was null or undefined when calling setOrderId.');
        }

        this.orderIdSubject.next(orderId);
    }
    
    public getOrderId() {
        return this.orderIdSubject.asObservable();
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }

    /**
     * 
     * 
     * @param orderId 
     * @param locale 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrderDetail(orderId: string, locale?: string, observe?: 'body', reportProgress?: boolean): Observable<SmartDineResponseOrderModel>;
    public getOrderDetail(orderId: string, locale?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SmartDineResponseOrderModel>>;
    public getOrderDetail(orderId: string, locale?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SmartDineResponseOrderModel>>;
    public getOrderDetail(orderId: string, locale?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (orderId === null || orderId === undefined) {
            throw new Error('Required parameter orderId was null or undefined when calling getOrderDetail.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (locale !== undefined && locale !== null) {
            queryParameters = queryParameters.set('locale', <any>locale);
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<SmartDineResponseOrderModel>('get',`${this.basePath}/api/user/orders/${encodeURIComponent(String(orderId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param restaurantId 
     * @param restaurantBranchId 
     * @param statusList 
     * @param fromDate 
     * @param toDate 
     * @param locale 
     * @param pageNo 
     * @param rows 
     * @param sortByFields 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrders(restaurantId?: string, restaurantBranchId?: string, statusList?: Array<string>, fromDate?: Date, toDate?: Date, locale?: string, pageNo?: number, rows?: number, sortByFields?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<SmartDineResponseOrderSummaryModel>;
    public getOrders(restaurantId?: string, restaurantBranchId?: string, statusList?: Array<string>, fromDate?: Date, toDate?: Date, locale?: string, pageNo?: number, rows?: number, sortByFields?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SmartDineResponseOrderSummaryModel>>;
    public getOrders(restaurantId?: string, restaurantBranchId?: string, statusList?: Array<string>, fromDate?: Date, toDate?: Date, locale?: string, pageNo?: number, rows?: number, sortByFields?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SmartDineResponseOrderSummaryModel>>;
    public getOrders(restaurantId?: string, restaurantBranchId?: string, statusList?: Array<string>, fromDate?: Date, toDate?: Date, locale?: string, pageNo?: number, rows?: number, sortByFields?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (restaurantId !== undefined && restaurantId !== null) {
            queryParameters = queryParameters.set('restaurantId', <any>restaurantId);
        }
        if (restaurantBranchId !== undefined && restaurantBranchId !== null) {
            queryParameters = queryParameters.set('restaurantBranchId', <any>restaurantBranchId);
        }
        if (statusList) {
            statusList.forEach((element) => {
                queryParameters = queryParameters.append('statusList', <any>element);
            })
        }
        if (fromDate !== undefined && fromDate !== null) {
            queryParameters = queryParameters.set('fromDate', <any>fromDate.toISOString());
        }
        if (toDate !== undefined && toDate !== null) {
            queryParameters = queryParameters.set('toDate', <any>toDate.toISOString());
        }
        if (locale !== undefined && locale !== null) {
            queryParameters = queryParameters.set('locale', <any>locale);
        }
        if (pageNo !== undefined && pageNo !== null) {
            queryParameters = queryParameters.set('pageNo', <any>pageNo);
        }
        if (rows !== undefined && rows !== null) {
            queryParameters = queryParameters.set('rows', <any>rows);
        }
        if (sortByFields) {
            sortByFields.forEach((element) => {
                queryParameters = queryParameters.append('sortByFields', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<SmartDineResponseOrderSummaryModel>('get',`${this.basePath}/api/user/orders`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param locale 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public submitOrder(body: OrderModel, locale?: string, observe?: 'body', reportProgress?: boolean): Observable<SmartDineResponseOrderModel>;
    public submitOrder(body: OrderModel, locale?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SmartDineResponseOrderModel>>;
    public submitOrder(body: OrderModel, locale?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SmartDineResponseOrderModel>>;
    public submitOrder(body: OrderModel, locale?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling submitOrder.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (locale !== undefined && locale !== null) {
            queryParameters = queryParameters.set('locale', <any>locale);
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<SmartDineResponseOrderModel>('post',`${this.basePath}/api/user/orders`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
