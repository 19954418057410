/**
 * SmartdineNow API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface OfferRuleModel { 
    offerRuleId?: string;
    restaurantId?: string;
    restaurantBranchId?: string;
    offerCode?: string;
    offerName?: string;
    offerRuleLevel?: OfferRuleModel.OfferRuleLevelEnum;
    minItemQty?: number;
    maxItemQty?: number;
    minPrice?: number;
    maxPrice?: number;
    discountPercRate?: number;
    discountFlatRate?: number;
    maxNoOfTimesAllowed?: number;
    offerRuleType?: OfferRuleModel.OfferRuleTypeEnum;
    applicableItemTypes?: Array<OfferRuleModel.ApplicableItemTypesEnum>;
    active?: boolean;
}
export namespace OfferRuleModel {
    export type OfferRuleLevelEnum = 'ITEM_LEVEL' | 'CART_LEVEL';
    export const OfferRuleLevelEnum = {
        ITEMLEVEL: 'ITEM_LEVEL' as OfferRuleLevelEnum,
        CARTLEVEL: 'CART_LEVEL' as OfferRuleLevelEnum
    };
    export type OfferRuleTypeEnum = 'FLAT_FIXED_DISCOUNT' | 'FLAT_PERC_DISCOUNT' | 'FLAT_FIXED_DISCOUNT_BY_ORDERS_COUNT' | 'FLAT_PERC_DISCOUNT_BY_ORDERS_COUNT';
    export const OfferRuleTypeEnum = {
        FIXEDDISCOUNT: 'FLAT_FIXED_DISCOUNT' as OfferRuleTypeEnum,
        PERCDISCOUNT: 'FLAT_PERC_DISCOUNT' as OfferRuleTypeEnum,
        FIXEDDISCOUNTBYORDERSCOUNT: 'FLAT_FIXED_DISCOUNT_BY_ORDERS_COUNT' as OfferRuleTypeEnum,
        PERCDISCOUNTBYORDERSCOUNT: 'FLAT_PERC_DISCOUNT_BY_ORDERS_COUNT' as OfferRuleTypeEnum
    };
    export type ApplicableItemTypesEnum = 'FOOD' | 'LIQUOR' | 'FOOD_5' | 'FOOD_12' | 'FOOD_18';
    export const ApplicableItemTypesEnum = {
        FOOD: 'FOOD' as ApplicableItemTypesEnum,
        LIQUOR: 'LIQUOR' as ApplicableItemTypesEnum,
        FOOD5: 'FOOD_5' as ApplicableItemTypesEnum,
        FOOD12: 'FOOD_12' as ApplicableItemTypesEnum,
        FOOD18: 'FOOD_18' as ApplicableItemTypesEnum
    };
}