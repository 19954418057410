import { Directive, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { startWith } from 'rxjs/operators';

@Directive({
  selector: '[sdAppear]'
})
export class AppearDirective {

  @Input() scrollRef: HTMLElement;

  @Input() spotlightRef: HTMLElement;

  @Output()
  public sdAppear = new EventEmitter();

  public subscription: Subscription;

  constructor(
    private element: ElementRef,
  ) { }

  public ngOnInit() {
    this.subscribe();
  }

  public ngOnDestroy() {
    this.unsubscribe();
  }

  public subscribe() {
    let target: any = window;
    if (this.scrollRef) { target = this.scrollRef; }
    this.subscription = fromEvent(target, 'scroll').subscribe({
      next: (_event: Event) => {
        if (this.isVisible(this.element.nativeElement)) {
          this.sdAppear.emit();
        }
        
      }
    })
  }

  public unsubscribe() {
    this.subscription.unsubscribe();
  }

  private isVisible(element: HTMLElement) {
    const refElementRect = this.spotlightRef.getBoundingClientRect();
    const targetElementRect = element.getBoundingClientRect();
    const result = (
      targetElementRect.top >= refElementRect.top &&
      targetElementRect.left >= refElementRect.left &&
      targetElementRect.bottom <= refElementRect.bottom &&
      targetElementRect.right <= refElementRect.right
    );
    // console.log(refElementRect, targetElementRect);
    if (result) {
      console.log(' > someone has focus', element);
    }
    return result;
  }

}
