import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RedirectUserService {
  private redirectUrl: string | null = null;

  public setRedirectUrl(url: string): void {
    this.redirectUrl = url;
  }

  public getRedirectUrl(): string | null {
    return this.redirectUrl;
  }

  public clearRedirectUrl(): void {
    this.redirectUrl = null;
  }
  
}