import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  private modalSource = new BehaviorSubject('modalSource');
  currentModal = this.modalSource.asObservable();

  constructor() { }

  changeCurrentModalId(currentModalId: string) {
    this.modalSource.next(currentModalId)
  }
}
