import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MenuItemMapping } from '../../../models/menu-item-mapping/menu-item-mapping.model';
import { MenuModel} from 'src/app/swagger';
import { ModalController } from '@ionic/angular';

interface MenuItemMappingsDictionary {
  [key: string]: MenuItemMapping;
}

interface Menu extends MenuModel {
  itemsMappings?: MenuItemMapping[];
}

interface SdEvent extends MouseEvent {
  value: any;
}

@Component({
  selector: 'catalog-side-menus',
  templateUrl: './catalog-side-menus.component.html',
  styleUrls: ['./catalog-side-menus.component.scss'],
})
export class CatalogSideMenusComponent implements OnInit {

  public static readonly id = 'menu-display-modal';

  @Input() public menus: Menu[] = [];
  @Input() public value: MenuModel | null = null;
  @Input() public totalCount: number = 0;
  @Input() public isMobileView = false;
  @Input() public isAllActive = true;

  @Output() menuClick: EventEmitter<SdEvent> = new EventEmitter();

  constructor(private modalController: ModalController) {}

  ngOnInit(): void {
    
  }
  
  public onMenuClick(event: MouseEvent, menu: MenuModel | string) {
    let newEvent = event as SdEvent;
    if (menu === 'All') {
      this.isAllActive = true;
      newEvent.value = 'All';
    } else {
      this.isAllActive = false;
      newEvent.value = menu;
    }
    this.menuClick.emit(newEvent);

    if (this.isMobileView) {
      this.modalController.dismiss({ value: menu }, 'dismiss');
    }
  }

  public onCloseClick() {
    this.modalController.dismiss({}, 'dismiss');
  }

}
