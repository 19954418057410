import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { MenuEntity } from 'src/app/swagger';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class MenuNavService {

  private readonly storageKey = 'current-menu-nav';
  private menus: MenuEntity[] = [];
  private subject: BehaviorSubject<MenuEntity[]> = new BehaviorSubject<MenuEntity[]>([]);

  constructor(
    private storageService: StorageService
  ) {
    this.init();
  }

  get lastMenuId(): string | null {
    const lastMenu = this.menus[this.menus.length - 1];
    let lastMenuId = null;
    if (lastMenu) {
      lastMenuId = lastMenu.menuId;
    }
    return lastMenuId;
  }

  private dispatchEvent() {
    this.subject.next(this.menus);
    this.storageService.set(this.storageKey, this.menus);
  }

  public async init() {
    const currentNavMenus: MenuEntity[] = await this.storageService.get(this.storageKey);
    if (currentNavMenus && currentNavMenus.length) {
      this.menus = currentNavMenus;
      this.subject.next(this.menus);
    }
  }

  public getMenus(): Observable<MenuEntity[]> {
    return this.subject.asObservable();
  }

  public push(menu: MenuEntity) {
    const parentMenuId = menu.parentMenuId;
    if (!parentMenuId) {
      this.menus = [];
    } else if (!this.lastMenuId) {
      // TODO: parentMenuId is there and lastMenuId is not there.
      return;
    } else if (parentMenuId !== this.lastMenuId) {
      this.pop();
      this.push(menu);
      return;
    }

    this.menus.push(menu);
    this.dispatchEvent();
  }

  public pop() {
    this.menus.pop();
    this.dispatchEvent();
  }

  public set(menus: MenuEntity[]) {
    if (!menus.length) {
      this.clear();
      return;
    }

    this.menus = menus;
    this.dispatchEvent();
  }

  public clear() {
    this.menus = [];
    this.dispatchEvent();
  }
}
