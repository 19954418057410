// google-places.service.ts

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GooglePlacesService {
  private apiKey = 'AIzaSyB9ZVCAdsIiS7kZT13CKUdvAGqS1fg1kyE';

  constructor(private http: HttpClient) {}

  getPlaceId(restaurantName: string): Observable<any> {
    const apiUrl = `https://maps.googleapis.com/maps/api/place/findplacefromtext/json?input=${restaurantName}&inputtype=textquery&fields=place_id&key=${this.apiKey}`;
    return this.http.get(apiUrl);
  }

  getReviews(placeId: string): Observable<any> {
    const apiUrl = `https://maps.googleapis.com/maps/api/place/details/json?place_id=${placeId}&fields=name,reviews&key=${this.apiKey}`;
    return this.http.get(apiUrl);
  }
  
}
