/**
 * SmartdineNow API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface RestaurantBranchTableEntity { 
    creator?: string;
    modifier?: string;
    createdAt?: Date;
    modifiedAt?: Date;
    tableId?: string;
    tableCode?: string;
    capacity?: number;
    captain?: string;
    restaurantId?: string;
    restaurantBranchId?: string;
}