import { Component, Input, OnInit } from '@angular/core';
import { MenuItem } from '../../services/side-menu/side-menu.service';
import { StorageService } from '../../services/storage/storage.service';

type SideMenuState = 'collapsed' | 'expanded';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss'],
})
export class SideBarComponent implements OnInit {

  private readonly storageKey = 'side-bar-status';

  public sideMenuState: SideMenuState = 'expanded';

  constructor(
    private storageService: StorageService,
  ) { }

  public async onSideMenuExpandCollapseClick() {
    if (this.sideMenuState === 'expanded') {
      this.sideMenuState = 'collapsed';
    } else {
      this.sideMenuState = 'expanded';
    }
    await this.storageService.set(this.storageKey, this.sideMenuState);
  }

  public ngOnInit() {
    this.setSideBarStatus();
  }

  private setSideBarStatus() {
    this.storageService.get(this.storageKey).then(
      (value: SideMenuState | null) => {
        this.sideMenuState = 'expanded';
        if (value) {
          setTimeout(() => {
            this.sideMenuState = value;
          }, 500); // Notifying the users that sidebar has more info & it is currently getting minimized..
        } 
      },
    );
  }

}
