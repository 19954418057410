/**
 * SmartdineNow API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CurrencyModel } from './currencyModel';
import { OrderModel } from './orderModel';
import { OrderPriceModel } from './orderPriceModel';

export interface ParentOrderModel { 
    parentOrderId?: string;
    parentOrderNumber?: number;
    orderType?: ParentOrderModel.OrderTypeEnum;
    restaurantId?: string;
    restaurantBranchId?: string;
    tableId?: string;
    userId?: string;
    customerPhoneNumber?: string;
    orderIds?: Array<string>;
    orderStatus?: ParentOrderModel.OrderStatusEnum;
    orderPaymentStatus?: ParentOrderModel.OrderPaymentStatusEnum;
    orderPriceModel?: OrderPriceModel;
    nextPossibleOrderPaymentStatuses?: Array<ParentOrderModel.NextPossibleOrderPaymentStatusesEnum>;
    orderModelsMap?: { [key: string]: OrderModel; };
    paymentPreference?: ParentOrderModel.PaymentPreferenceEnum;
    currencyModel?: CurrencyModel;
}
export namespace ParentOrderModel {
    export type OrderTypeEnum = 'DINE_IN' | 'DELIVERY' | 'TAKEAWAY';
    export const OrderTypeEnum = {
        DINEIN: 'DINE_IN' as OrderTypeEnum,
        DELIVERY: 'DELIVERY' as OrderTypeEnum,
        TAKEAWAY: 'TAKEAWAY' as OrderTypeEnum
    };
    export type OrderStatusEnum = 'DRAFT' | 'ITEMS_RESERVED' | 'SUBMITTED' | 'CANCELLED' | 'DECLINED' | 'ACCEPTED' | 'PREPARING' | 'PREPARED' | 'DELIVERED' | 'NOT_ACCEPTED' | 'PICKED_UP';
    export const OrderStatusEnum = {
        DRAFT: 'DRAFT' as OrderStatusEnum,
        ITEMSRESERVED: 'ITEMS_RESERVED' as OrderStatusEnum,
        SUBMITTED: 'SUBMITTED' as OrderStatusEnum,
        CANCELLED: 'CANCELLED' as OrderStatusEnum,
        DECLINED: 'DECLINED' as OrderStatusEnum,
        ACCEPTED: 'ACCEPTED' as OrderStatusEnum,
        PREPARING: 'PREPARING' as OrderStatusEnum,
        PREPARED: 'PREPARED' as OrderStatusEnum,
        DELIVERED: 'DELIVERED' as OrderStatusEnum,
        NOTACCEPTED: 'NOT_ACCEPTED' as OrderStatusEnum,
        PICKEDUP: 'PICKED_UP' as OrderStatusEnum
    };
    export type OrderPaymentStatusEnum = 'PAY_LATER' | 'PAYMENT_INITIATED' | 'PAYMENT_SUCCESS' | 'PAYMENT_REFUNDED' | 'PAYMENT_FAILED' | 'PAID' | 'REFUND_INITIATED' | 'REFUND_SUCCESS' | 'PAYMENT_NA' | 'PAY_BY_CASH' | 'PAID_BY_CASH';
    export const OrderPaymentStatusEnum = {
        PAYLATER: 'PAY_LATER' as OrderPaymentStatusEnum,
        PAYMENTINITIATED: 'PAYMENT_INITIATED' as OrderPaymentStatusEnum,
        PAYMENTSUCCESS: 'PAYMENT_SUCCESS' as OrderPaymentStatusEnum,
        PAYMENTREFUNDED: 'PAYMENT_REFUNDED' as OrderPaymentStatusEnum,
        PAYMENTFAILED: 'PAYMENT_FAILED' as OrderPaymentStatusEnum,
        PAID: 'PAID' as OrderPaymentStatusEnum,
        REFUNDINITIATED: 'REFUND_INITIATED' as OrderPaymentStatusEnum,
        REFUNDSUCCESS: 'REFUND_SUCCESS' as OrderPaymentStatusEnum,
        PAYMENTNA: 'PAYMENT_NA' as OrderPaymentStatusEnum,
        PAYBYCASH: 'PAY_BY_CASH' as OrderPaymentStatusEnum,
        PAIDBYCASH: 'PAID_BY_CASH' as OrderPaymentStatusEnum
    };
    export type NextPossibleOrderPaymentStatusesEnum = 'PAY_LATER' | 'PAYMENT_INITIATED' | 'PAYMENT_SUCCESS' | 'PAYMENT_REFUNDED' | 'PAYMENT_FAILED' | 'PAID' | 'REFUND_INITIATED' | 'REFUND_SUCCESS' | 'PAYMENT_NA' | 'PAY_BY_CASH' | 'PAID_BY_CASH';
    export const NextPossibleOrderPaymentStatusesEnum = {
        PAYLATER: 'PAY_LATER' as NextPossibleOrderPaymentStatusesEnum,
        PAYMENTINITIATED: 'PAYMENT_INITIATED' as NextPossibleOrderPaymentStatusesEnum,
        PAYMENTSUCCESS: 'PAYMENT_SUCCESS' as NextPossibleOrderPaymentStatusesEnum,
        PAYMENTREFUNDED: 'PAYMENT_REFUNDED' as NextPossibleOrderPaymentStatusesEnum,
        PAYMENTFAILED: 'PAYMENT_FAILED' as NextPossibleOrderPaymentStatusesEnum,
        PAID: 'PAID' as NextPossibleOrderPaymentStatusesEnum,
        REFUNDINITIATED: 'REFUND_INITIATED' as NextPossibleOrderPaymentStatusesEnum,
        REFUNDSUCCESS: 'REFUND_SUCCESS' as NextPossibleOrderPaymentStatusesEnum,
        PAYMENTNA: 'PAYMENT_NA' as NextPossibleOrderPaymentStatusesEnum,
        PAYBYCASH: 'PAY_BY_CASH' as NextPossibleOrderPaymentStatusesEnum,
        PAIDBYCASH: 'PAID_BY_CASH' as NextPossibleOrderPaymentStatusesEnum
    };
    export type PaymentPreferenceEnum = 'PAY_BY_ONLINE' | 'PAY_BY_CASH';
    export const PaymentPreferenceEnum = {
        ONLINE: 'PAY_BY_ONLINE' as PaymentPreferenceEnum,
        CASH: 'PAY_BY_CASH' as PaymentPreferenceEnum
    };
}