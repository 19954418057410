import { Component,Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent implements OnInit {

  
  @Input() placeholder: string = 'Search...';
  @Output() searchChange = new EventEmitter<string>();

  public searchText: string = '';
  public timerID = null;

  constructor() { }

  ngOnInit() {}

  public onSearchChange(event: any): void {
    let that = this;

    clearTimeout(that.timerID);
    that.timerID = setTimeout(function(){
      that.searchChange.emit(that.searchText);
    }, 300)
  }

}
  