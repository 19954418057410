/**
 * SmartdineNow API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { InventoryMasterModel } from '../model/inventoryMasterModel';
import { SmartDineResponseInventoryMasterModel } from '../model/smartDineResponseInventoryMasterModel';
import { SmartDineResponseListInventoryMasterModel } from '../model/smartDineResponseListInventoryMasterModel';
import { SortByParam } from '../model/sortByParam';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class InventoryMasterControllerService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param body 
     * @param restaurantId 
     * @param restaurantBranchId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createInventoryMaster(body: InventoryMasterModel, restaurantId: string, restaurantBranchId: string, observe?: 'body', reportProgress?: boolean): Observable<SmartDineResponseInventoryMasterModel>;
    public createInventoryMaster(body: InventoryMasterModel, restaurantId: string, restaurantBranchId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SmartDineResponseInventoryMasterModel>>;
    public createInventoryMaster(body: InventoryMasterModel, restaurantId: string, restaurantBranchId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SmartDineResponseInventoryMasterModel>>;
    public createInventoryMaster(body: InventoryMasterModel, restaurantId: string, restaurantBranchId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling createInventoryMaster.');
        }

        if (restaurantId === null || restaurantId === undefined) {
            throw new Error('Required parameter restaurantId was null or undefined when calling createInventoryMaster.');
        }

        if (restaurantBranchId === null || restaurantBranchId === undefined) {
            throw new Error('Required parameter restaurantBranchId was null or undefined when calling createInventoryMaster.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (restaurantId !== undefined && restaurantId !== null) {
            queryParameters = queryParameters.set('restaurantId', <any>restaurantId);
        }
        if (restaurantBranchId !== undefined && restaurantBranchId !== null) {
            queryParameters = queryParameters.set('restaurantBranchId', <any>restaurantBranchId);
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<SmartDineResponseInventoryMasterModel>('post',`${this.basePath}/api/admin/inventorymasters`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param restaurantId 
     * @param restaurantBranchId 
     * @param inventoryMasterId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteInventoryMaster(restaurantId: string, restaurantBranchId: string, inventoryMasterId: string, observe?: 'body', reportProgress?: boolean): Observable<SmartDineResponseInventoryMasterModel>;
    public deleteInventoryMaster(restaurantId: string, restaurantBranchId: string, inventoryMasterId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SmartDineResponseInventoryMasterModel>>;
    public deleteInventoryMaster(restaurantId: string, restaurantBranchId: string, inventoryMasterId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SmartDineResponseInventoryMasterModel>>;
    public deleteInventoryMaster(restaurantId: string, restaurantBranchId: string, inventoryMasterId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (restaurantId === null || restaurantId === undefined) {
            throw new Error('Required parameter restaurantId was null or undefined when calling deleteInventoryMaster.');
        }

        if (restaurantBranchId === null || restaurantBranchId === undefined) {
            throw new Error('Required parameter restaurantBranchId was null or undefined when calling deleteInventoryMaster.');
        }

        if (inventoryMasterId === null || inventoryMasterId === undefined) {
            throw new Error('Required parameter inventoryMasterId was null or undefined when calling deleteInventoryMaster.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (restaurantId !== undefined && restaurantId !== null) {
            queryParameters = queryParameters.set('restaurantId', <any>restaurantId);
        }
        if (restaurantBranchId !== undefined && restaurantBranchId !== null) {
            queryParameters = queryParameters.set('restaurantBranchId', <any>restaurantBranchId);
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<SmartDineResponseInventoryMasterModel>('delete',`${this.basePath}/api/admin/inventorymasters/${encodeURIComponent(String(inventoryMasterId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param restaurantId 
     * @param restaurantBranchId 
     * @param inventoryMasterId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getInventoryMaster(restaurantId: string, restaurantBranchId: string, inventoryMasterId: string, observe?: 'body', reportProgress?: boolean): Observable<SmartDineResponseInventoryMasterModel>;
    public getInventoryMaster(restaurantId: string, restaurantBranchId: string, inventoryMasterId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SmartDineResponseInventoryMasterModel>>;
    public getInventoryMaster(restaurantId: string, restaurantBranchId: string, inventoryMasterId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SmartDineResponseInventoryMasterModel>>;
    public getInventoryMaster(restaurantId: string, restaurantBranchId: string, inventoryMasterId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (restaurantId === null || restaurantId === undefined) {
            throw new Error('Required parameter restaurantId was null or undefined when calling getInventoryMaster.');
        }

        if (restaurantBranchId === null || restaurantBranchId === undefined) {
            throw new Error('Required parameter restaurantBranchId was null or undefined when calling getInventoryMaster.');
        }

        if (inventoryMasterId === null || inventoryMasterId === undefined) {
            throw new Error('Required parameter inventoryMasterId was null or undefined when calling getInventoryMaster.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (restaurantId !== undefined && restaurantId !== null) {
            queryParameters = queryParameters.set('restaurantId', <any>restaurantId);
        }
        if (restaurantBranchId !== undefined && restaurantBranchId !== null) {
            queryParameters = queryParameters.set('restaurantBranchId', <any>restaurantBranchId);
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<SmartDineResponseInventoryMasterModel>('get',`${this.basePath}/api/admin/inventorymasters/${encodeURIComponent(String(inventoryMasterId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param restaurantId 
     * @param restaurantBranchId 
     * @param pageNo 
     * @param pageSize 
     * @param sortByParams 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getInventoryMasters(restaurantId: string, restaurantBranchId: string, pageNo?: number, pageSize?: number, sortByParams?: Array<SortByParam>, observe?: 'body', reportProgress?: boolean): Observable<SmartDineResponseListInventoryMasterModel>;
    public getInventoryMasters(restaurantId: string, restaurantBranchId: string, pageNo?: number, pageSize?: number, sortByParams?: Array<SortByParam>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SmartDineResponseListInventoryMasterModel>>;
    public getInventoryMasters(restaurantId: string, restaurantBranchId: string, pageNo?: number, pageSize?: number, sortByParams?: Array<SortByParam>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SmartDineResponseListInventoryMasterModel>>;
    public getInventoryMasters(restaurantId: string, restaurantBranchId: string, pageNo?: number, pageSize?: number, sortByParams?: Array<SortByParam>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (restaurantId === null || restaurantId === undefined) {
            throw new Error('Required parameter restaurantId was null or undefined when calling getInventoryMasters.');
        }

        if (restaurantBranchId === null || restaurantBranchId === undefined) {
            throw new Error('Required parameter restaurantBranchId was null or undefined when calling getInventoryMasters.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (restaurantId !== undefined && restaurantId !== null) {
            queryParameters = queryParameters.set('restaurantId', <any>restaurantId);
        }
        if (restaurantBranchId !== undefined && restaurantBranchId !== null) {
            queryParameters = queryParameters.set('restaurantBranchId', <any>restaurantBranchId);
        }
        if (pageNo !== undefined && pageNo !== null) {
            queryParameters = queryParameters.set('pageNo', <any>pageNo);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('pageSize', <any>pageSize);
        }
        if (sortByParams) {
            sortByParams.forEach((element) => {
                queryParameters = queryParameters.append('sortByParams', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<SmartDineResponseListInventoryMasterModel>('get',`${this.basePath}/api/admin/inventorymasters`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param restaurantId 
     * @param restaurantBranchId 
     * @param inventoryMasterId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateInventoryMaster(body: InventoryMasterModel, restaurantId: string, restaurantBranchId: string, inventoryMasterId: string, observe?: 'body', reportProgress?: boolean): Observable<SmartDineResponseInventoryMasterModel>;
    public updateInventoryMaster(body: InventoryMasterModel, restaurantId: string, restaurantBranchId: string, inventoryMasterId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SmartDineResponseInventoryMasterModel>>;
    public updateInventoryMaster(body: InventoryMasterModel, restaurantId: string, restaurantBranchId: string, inventoryMasterId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SmartDineResponseInventoryMasterModel>>;
    public updateInventoryMaster(body: InventoryMasterModel, restaurantId: string, restaurantBranchId: string, inventoryMasterId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateInventoryMaster.');
        }

        if (restaurantId === null || restaurantId === undefined) {
            throw new Error('Required parameter restaurantId was null or undefined when calling updateInventoryMaster.');
        }

        if (restaurantBranchId === null || restaurantBranchId === undefined) {
            throw new Error('Required parameter restaurantBranchId was null or undefined when calling updateInventoryMaster.');
        }

        if (inventoryMasterId === null || inventoryMasterId === undefined) {
            throw new Error('Required parameter inventoryMasterId was null or undefined when calling updateInventoryMaster.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (restaurantId !== undefined && restaurantId !== null) {
            queryParameters = queryParameters.set('restaurantId', <any>restaurantId);
        }
        if (restaurantBranchId !== undefined && restaurantBranchId !== null) {
            queryParameters = queryParameters.set('restaurantBranchId', <any>restaurantBranchId);
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<SmartDineResponseInventoryMasterModel>('put',`${this.basePath}/api/admin/inventorymasters/${encodeURIComponent(String(inventoryMasterId))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
